import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ShopService } from 'src/app/services/shop.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { GooglePlaces } from 'src/app/models/google-places.model';
import { Location, Appearance } from '@angular-material-extensions/google-maps-autocomplete';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss']
})
export class DeliveryAddressComponent implements OnInit {

  hasStreetNumber = true;
  outOfDeliveryArea = false;
  longitude: number;
  latitude: number;
  address: string;
  streetNumber: string;
  streetNumberSubmitted = false;
  googlePlacesObject: GooglePlaces;

  @ViewChild('streetNumberEl') streetNumberEl: ElementRef;
  @ViewChild('addressEl') addressEl: ElementRef;

  constructor(
    private shop: ShopService,
    private nav: NavigationService
  ) { }

  ngOnInit() {
    if (!environment.production) {
      alert('Smood API does not work in DEV');
    }
  }

  onStreetNumberChange() {
    // if (this.streetNumber) {
    //   this.hasStreetNumber = true;
    // }
    
  }

  onAddressChange() {
    console.log('this.address:', this.address);
  }

  updateAddressWithStreetNumber() {
    const a = this.googlePlacesObject.formatted_address;
    const commaSplitted = a.split(',');
    // remove previous street number from address
    let newAddress = commaSplitted[0].replace(/[0-9]/g, '') + ' ' + this.streetNumber;
    commaSplitted.forEach((c, i) => {
      if (i > 0) {
        newAddress += ', ' + c;
      }
    });
    this.address = newAddress;
  }

  onSubmitStreetNumber() {
    if (!this.streetNumber) {
      return;
    }
    this.streetNumberSubmitted = true;
    this.updateAddressWithStreetNumber();
    setTimeout(() => {
      this.addressEl.nativeElement.focus();
    }, 0);    
  }

  onAutocompleteSelected(event: GooglePlaces) {
    console.log('GooglePlaces event: ', event);
    this.googlePlacesObject = event;
    this.longitude = event.geometry.location.lng;
    this.latitude = event.geometry.location.lat;
    this.outOfDeliveryArea = false;
    // check if streetNumber is present
    let hasStreetNumber = false;
    this.streetNumberSubmitted = false;
    event.address_components.forEach(
      (address) => {
        if (address.types[0] === 'street_number') {
          hasStreetNumber = true;
        }
      }
    );
    this.hasStreetNumber = hasStreetNumber;
    if (!hasStreetNumber) {
      setTimeout(() => {
        this.streetNumberEl.nativeElement.focus();
      }, 0);
      return; // cancel if no streetNumber present
    }

    this.shop.getDeliveryZoneInfoByGooglePlaces(event).subscribe(
      (res) => {
        if (!res) {
          this.outOfDeliveryArea = true;
        } else {
          this.shop.restaurants.value.forEach((r) => {
            if (r.restaurantId === res.restaurantId) {

              this.shop.orderType.next('delivery');
              const sq = JSON.parse(JSON.stringify(this.nav.shopQueryParams.value) || null);
              sq.restaurantId = res.restaurantId;
              sq.orderType = 'delivery';
              sq.col1 = 'categories';
              this.nav.shopQueryParams.next(sq);
              this.shop.restaurant.next(r);
            }
          });
        }
      }
    );
  }
  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  // onGermanAddressMapped($event: GermanAddress) {
  //   console.log('onGermanAddressMapped', $event);
  // }


}
