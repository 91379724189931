import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ShopQueryParams } from 'src/app/models/shop-query-params.model';
import { NavigationService } from 'src/app/services/navigation.service';
import { ShopService } from 'src/app/services/shop.service';
import { RoutingService } from 'src/app/services/routing.service';
import { TranslateService } from '@ngx-translate/core';
import { Order } from 'src/app/models/order.model';
import { orderQueryParams } from 'src/app/models/order-query-params.model';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-completed',
  templateUrl: './order-completed.component.html',
  styleUrls: ['./order-completed.component.scss']
})
export class OrderCompletedComponent implements OnInit {
  state: string;
  orderId = null;
  shopQueryParams: ShopQueryParams = {
    restaurantId: null,
    orderType: null,
    col1: null,
    col2: null,
    lang: null,
    categoryId: null
  };
  orderQueryParams: orderQueryParams = {
    col1: null,
    state: null,
    col2: null
  };
  subscription: Subscription;
  browserRefresh = false;
  shopQueryParams$: any;
  constructor(
    private shop: ShopService,
    private nav: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
  ) {



  }

  ngOnInit(): void {
    console.log('order-completed');
    this.route.queryParams.subscribe(params => {
      this.orderId = params['r'];
      if (this.orderId) {
        console.log('orderId');
        let temp = window.location.href.substr(window.location.href.lastIndexOf('/') + 1).split('?');
        this.state = temp[0];
        console.log(temp);
        if (this.state === 'success') {
          console.log('success');
          this.shop.finishOrder(this.orderId).subscribe((res) => {
            const sq = JSON.parse(JSON.stringify(this.orderQueryParams));
            sq.col1 = 'order-completed';
            sq.state = this.state;
            sq.col2 = null;
            this.nav.col2.next(null);
            this.router.navigateByUrl(this.nav.paramsFromObject(sq, RoutingService.shopPrefix));
          }, (err) => {
            console.log(err);
          });
        }
      }
    });
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !this.router.navigated;
        console.log(this.browserRefresh)
      }
  });
  }

}
