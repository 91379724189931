import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { AuthService } from '../services/auth.service';
import { AccessData } from '../models/access-data.model';
import { ShopService } from '../services/shop.service';
import { Order } from '../models/order.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

  accessData: AccessData;
  accessData$: any;
  loginOpen = false;
  registrationOpen = false;
  dashboardOpen = false;
  profileOpen = false;
  changePasswordOpen = false;
  helpOpen = false;
  items = [
    {title: 'LOGIN', content: 'login', isOpen: false, auth: false},
    {title: 'REGISTRATION', content: 'registration', isOpen: false, auth: false},
    {title: 'RESET_PASSWORD', content: 'resetPassword', isOpen: false, auth: false},
    {title: 'PROFILE', content: 'profile', isOpen: false, auth: true},
    {title: 'CHANGE_PASSWORD', content: 'changePassword', isOpen: false, auth: true},
    {title: 'LOGOUT', content: 'logout', isOpen: false, auth: true},
  ];
  route$: any;
  area: any;

  constructor(
    private nav: NavigationService,
    private auth: AuthService,
    private shop: ShopService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private title: Title,
    private meta: Meta
  ) { }

  ngOnInit() {
    this.nav.col1.next(null);
    this.nav.col2.next(null);
    this.shop.order.next(new Order());
    this.accessData$ = this.auth.accessData.subscribe((a) => {this.accessData = a; });
    this.route$ = this.route.params.subscribe(
      (params) => {
        this.area = params.area;
        if(params.paymentMethodId && params.costumerId && params.state === 'success'){
          this.auth.finishCreditCard(params.costumerId,params.paymentMethodId).subscribe(
            (data)=>{
              console.log(data);
              this.area = 'profile';
            },(error)=>{
              console.log(error);
            }
          )
          this.toggleArea(this.area, true);
        }
        if (params.area) {
          this.toggleArea(params.area, true);
        }
      }
    );
    this.handleSEO();
    this.translate.onLangChange.subscribe(() => {
      this.handleSEO();
    });
  }

  ngOnDestroy() {
    this.accessData$.unsubscribe();
  }

  handleSEO() {
    this.title.setTitle(
      this.translate.instant('TITLE_TAG_USER_AREA')
    );
    this.meta.updateTag({
      name: 'description',
      content: this.translate.instant(
        'META_DESCRIPTION_USER_AREA'
      )
    });
  }

  toggleArea(area, routeToggle?) {
    this.items.forEach((i) => {
      if (i.content === area) {
        this.triggerItem(i);
      }
    });
  }

  triggerItem(item) {
    if (item.content !== this.area) {
      this.router.navigateByUrl(`user/${item.content}`);
      return;
    }
    if (item.content === 'logout' || item.content === 'resetPassword') {
      this.logout();
    } 
    this.items.forEach((i) => {
      if (i === item) {
        i.isOpen = !i.isOpen;
      } else {
        i.isOpen = false;
      }
    });
    
  }

  logout() {
    this.auth.logout();
  }

}
