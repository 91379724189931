import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Restaurant } from '../models/restaurant.model';
import { OpenHour } from '../models/open-hour.model';
import { Category } from '../models/category.model';
import { Product } from '../models/product.model';
import { Order } from '../models/order.model';
import { OpenDateTime } from '../models/open-date-time.model';
import { DeliveryZoneInfo } from '../models/delivery-zone-info.model';
import { Page } from '../models/page.model';
import { HttpClient } from '@angular/common/http';
import { CreditCard } from '../models/credit-card.model';

@Injectable()
export class ShopService {

  restaurants = new BehaviorSubject<Array<Restaurant>>([]);
  restaurant = new BehaviorSubject<Restaurant>(null);
  openHours = new BehaviorSubject<Array<OpenHour>>([]);
  orderType = new BehaviorSubject<string>(null); // 'delivery' or 'takeout'
  categories = new BehaviorSubject<Array<Category>>([]);
  category = new BehaviorSubject<Category>(null);
  products = new BehaviorSubject<Array<Product>>([]);
  basketProducts = new BehaviorSubject<Array<Product>>([]);
  order = new BehaviorSubject<Order>(new Order());
  selectedDateTime = new BehaviorSubject<string>(null);
  selectedDate = new BehaviorSubject<Date>(null);
  selectedTime = new BehaviorSubject<any>(null);
  openDateTimes = new BehaviorSubject<Array<OpenDateTime>>([]);
  deliveryZoneInfo = new BehaviorSubject<DeliveryZoneInfo>(null);
  pages = new BehaviorSubject<Array<Page>>([]);
  card = new BehaviorSubject(null);
  formattedGoogleAddress = new BehaviorSubject(null);

  // allergene
  signs = [
    { filterId: '4eb0e4ac-7533-4772-88fa-0b0b088895a7', image: '/assets/icons/chili.svg', name: 'HOT' },
    // { filterId: 'f6a87364-a4bf-495f-a4f6-edd7ee82c3b0', color: '#981810', name: 'VERY_HOT' },
    // { filterId: 'e47e0abc-d5b8-438b-a9ea-a33a1e6a6b66', color: '#82C746', name: 'VEGETARIAN' },
    { filterId: 'cfea6a75-ddf4-45fd-a6e1-566bee8214fd', image: '/assets/icons/vegan.svg', name: 'VEGAN' },
    // { filterId: '0896e009-216b-484b-9603-a942b02275d4', color: '#FFAF2E', name: 'CARBOHYDRATE_REDUCED' },
    /* { filterId: '1586c5e6-cd19-462c-b678-ab42d2cd9324', color: '#2AB9EA', name: 'UNTIL_22_O_CLOCK' }, */
  ];

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) { }

  initRestaurant(restaurantId: number) {
    let restaurant = this.restaurants.value.filter((r) => r.restaurantId === restaurantId)[0];
    /* new Restaurant */
    if (restaurantId === 10) restaurant = JSON.parse('{"restaurantId":10,"name":"Andermatt","email":"nooba@nooba.ch","seats":44,"description":null,"availableTypes":null,"phoneNumber":"+41 (0)43 243 60 06","address":"Haus Yara, Furkagasse 10, 6490 Andermatt","latitude":46.638621,"longitude":8.589213,"sortKey":1,"visibleState":20,"isTest":false,"isDeleted":false,"b2bCompanyId":null,"parentRestaurantId":null,"imageFile":null,"mediaId":null,"squareFileName":null,"rectangularFileName":null,"advertPortraitFileName":null,"advertLandscapeFileName":null,"deliveryInstructions":null,"deliveryLatestTime":null,"discountValue":0,"discountType":0,"b2bCompanyName":null,"b2bPincodeRequired":false,"uberEatsStoreId":null,"imageUrl":null,"isOn":true}');
    this.restaurant.next(restaurant);
  }

  getPages() {
    return this.http.get('https://cms.nooba.ch/wp-json/wp/v2/pages/?per_page=20');
  }

  getRestaurants() {
    return this.apiService.get('/api/Restaurants/All', 'json', false).pipe(
      map(
        (data: Array<Restaurant>) => {
          this.restaurants.next(data);

          return data;
        }
      )
    );
  }

  getOpenHours() {
    return this.apiService.get('/api/Restaurants/OpenHours/EditMode', 'json', false).pipe(
      map(
        (data: Array<OpenHour>) => {
          this.openHours.next(data);

          return data;
        }
      )
    );
  }

  getCategories() {
    if (this.orderType.value === 'delivery' && !this.deliveryZoneInfo.value) { return throwError('no delivery restaurantId'); }
    const c = this.orderType.value === 'delivery' ? 3 : 6;
    const r = this.orderType.value === 'delivery' ? this.deliveryZoneInfo.value.restaurantId : this.restaurant.value.restaurantId;
    return this.apiService.get(`/api/Categories?clienttypeid=${c}&restaurantId=${r}`, 'json', false).pipe(
      map(
        (data: Array<Category>) => {
          this.categories.next(data);

          return data;
        }
      )
    );
  }

  getProducts() {
    const c = this.orderType.value === 'delivery' ? 3 : 6;
    const r = this.restaurant.value.restaurantId;
    const cat = this.category.value.categoryId;
    return this.apiService.get(`/api/Products?clienttypeid=${c}&restaurantId=${r}&categoryid=${cat}`, 'json', false).pipe(
      map(
        (data: Array<Product>) => {
          this.products.next(data);

          return data;
        }
      )
    );
  }

  getNutritionValues(systemId) {
    return this.apiService.get('/api/Products/Nutrition/' + systemId, 'json', false).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }

  addProductToBasket(p: Product) {
    const order = this.order.value;
    let exists = false;
    if (!order.orderItems) {
      order.orderItems = [];
    }
    for (let i = 0; i < order.orderItems.length; i++) {
      if (order.orderItems[i].productId === p.productId) {
        order.orderItems[i].quantity += 1;
        exists = true;
      }
    }
    if (!exists) {
      order.orderItems.push({
        productId: p.productId,
        quantity: 1
      });
    }
    this.order.next(order);
    this.calculateOrder().subscribe((c) => {
      // calc done
    });
  }

  calculateOrder() {
    const order = this.order.value;
    const orderType = this.orderType.value;
    const clienttypeid = orderType === 'delivery' ? 3 : 6;
    order.clientTypeId = clienttypeid;
    order.restaurantId = this.restaurant.value.restaurantId;
    const items = order.orderItems.filter((o) => o.isAvailable || o.isAvailable !== false);
    order.orderItems = items;
    if (orderType === 'delivery') {
      order.deliveryZoneId = this.deliveryZoneInfo.value.deliveryZoneId;
      if (this.selectedTime.value) {
        order.deliveryCosts = this.selectedTime.value.deliveryCosts;
        order.deliveryZoneId = this.selectedTime.value.deliveryZoneId;
      }
    } else {
      order.deliveryCosts = null;
    }
    if (order.orderItems.length < 1) {
      order.bruttoTotal = 0;
      this.order.next(order);
      return;
    }
    return this.apiService.post('/api/Orders/Calculate', order, 'json', false).pipe(
      map(
        (data: Order) => {
          let o = data;
          for (var property in o) {
            if (o.hasOwnProperty(property)) {
              // delete all fields from object which ar 0, null
              if (o[property] === 0 || o[property] === null) {
                delete o[property];
              }
              // also delte empty arrays fields
              /*            if (typeof (o[property] === 'object')) {
                           if (o[property].length === 0 ){
                             delete o[property];
                           }
                         } */
            }
          }
          this.order.next(o);

          return data;
        }
      ),
      catchError(
        (error) => {
          this.order.next(error.error);
          return error;
        }
      )
    );
  }

  getDeliveryZoneInfoByGooglePlaces(address_components) {
    const body = {
      html_attributions: [],
      results: []
    };
    body.results.push(address_components);
    return this.apiService.post('api/Orders/Delivery/GeoCode', body, 'json', false).pipe(
      map(
        (data: DeliveryZoneInfo) => {
          const f = {
            street_number: null,
            street_name: null,
            zip: null,
            city: null,
          };
          address_components.address_components.forEach(a => {
            if (a.types[0] === 'street_number') {
              f.street_number = a.long_name;
            }
            if (a.types[0] === 'route') {
              f.street_name = a.long_name;
            }
            if (a.types[0] === 'postal_code') {
              f.zip = a.long_name;
            }
            if (a.types[0] === 'locality') {
              f.city = a.long_name;
            }
          });
          this.formattedGoogleAddress.next(f);
          this.deliveryZoneInfo.next(data);

          return data;
        }
      )
    );
  }

  getOpenDateTimes() {
    const c = this.orderType.value === 'delivery' ? 3 : 6;
    const r = this.restaurant.value.restaurantId;
    // takeout
    if (c === 6) {
      return this.apiService.get(`/api/Restaurants/OpenHours?clienttypeid=${c}&restaurantid=${r}&showDays=7`, 'json', false).pipe(
        map(
          (data: Array<OpenDateTime>) => {
            this.openDateTimes.next(data);

            return data;
          }
        )
      );
    }
    // delivery
    if (c === 3) {
      const d = this.deliveryZoneInfo.value;
      const lat = d.latitude;
      const long = d.longitude;
      return this.apiService.get(`/api/Restaurants/OpenHours/Smood?latitude=${lat}&longitude=${long}`, 'json', false).pipe(
        map(
          (data: Array<OpenDateTime>) => {
            this.openDateTimes.next(data);

            return data;
          }
        )
      );
    }
  }

  sendOrder() {
    const order = this.order.value;
    order.deliveryTime = this.selectedDateTime.value;
    const c = this.orderType.value === 'delivery' ? 3 : 6;
    if (c === 3) {
      order.deliveryZoneId = this.deliveryZoneInfo.value.deliveryZoneId;
    }
    return this.apiService.post('api/Orders/Init', order, 'json', false).pipe(
      map(
        (data) => {
          return data;
        }
      ), catchError(
        (error) => {
          console.log(error);
          return throwError(error);
        }
      )
    );
  }

  finishOrder(orderId) {
    return this.apiService.patch('api/Orders/' + orderId + '/Finish', null,'json', false).pipe(
      map(
        (data) => {
          return data;
        }
      ), catchError(
        (error) => {
          console.log(error);
          return throwError(error);
        }
      )
    );
  }

  getDiscoverItems() {
    return this.apiService.getVote('https://menuservice.inaffect.one/api/actualvotemenu', 'json', true).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }

  postDiscoverVote(body) {
    return this.apiService.postVote('https://menuservice.inaffect.one/api/actualvotemenu/vote', body, 'json', true).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }
}
