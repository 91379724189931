<div class="productDialog">
  <div class="dialog-header" mat-dialog-title>
    <button tabIndex="-1" class="close-btn" mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="productDescription">
    <h3>{{ getProductLang(data.product).nameLong }}</h3>
  </div>
  <div class="productDescription">
    <p>{{ getProductLang(data.product).metaDescription }}</p>
  </div>
  <div>
    <div class="productDescription">
      {{data.product.price | currencyNumber:'CHF':2}}
    </div>
  </div>
  <div style="margin-top: 15px;">
    <button mat-flat-button color="primary" style="width: 100%;" (click)="addToBasket()">{{ 'BASKET' | translate }}</button>
  </div>
  <div>
    <table class="nu">
      <tbody>
        <tr>
          <th style="text-align: left; color: #641B65;">
            {{ 'NUTRITIONINFO' | translate }}
          </th>
          <th style="text-align: right; color: #641B65;">
            {{ 'PER100G' | translate }}
          </th>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'ENERGY' | translate }}
          </td>
          <td style="text-align: right;">
            {{ data.product.energyKj }}kj ({{ data.product.energyKcal }} kcal)
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'GREASE' | translate }}
          </td>
          <td style="text-align: right;">
            {{ data.product.fat }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'ACIDS' | translate }}
          </td>
          <td style="text-align: right;">
            {{ data.product.saturatedFat }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'HYDRATES' | translate }}
          </td>
          <td style="text-align: right;">
            {{ data.product.carb }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'SUGAR' | translate }}
          </td>
          <td style="text-align: right;">
            {{ data.product.sugar }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'PROTEIN' | translate }}
          </td>
          <td style="text-align: right;">
            {{ data.product.protein }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'SALT' | translate }}
          </td>
          <td style="text-align: right;">
            {{ data.product.salt }}g
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <div>
      <h3 style="color: #641B65;">
        {{ 'ALLERGENS' | translate}}
      </h3>
      <div>
        <p style="overflow-wrap: break-word;"> {{ addSpacesAfterComma(getProductLang(data.product).allergensText) }}</p>
      </div>
    </div>
    <div>
      <h3 style="color: #641B65;">
        {{ 'INGREDIENTS' | translate}}
      </h3>
      <div>
        <p class="ingredients" [innerHTML]="addSpacesAfterComma(getProductLang(data.product).ingredientsText)"></p>
      </div>
    </div>
  </div>
</div>
