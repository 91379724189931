<div class="category-content">
  <div class="loading" *ngIf="loadingProducts">
    <mat-spinner></mat-spinner>
  </div>
  <div class="no-products" *ngIf="!loadingProducts && products?.length < 1">{{ 'NO_PRODUCTS_FOUND' | translate }}</div>
  <div class="products" *ngIf="products?.length">
    <div class="product" *ngFor="let p of products">
      <div class="title-signs">
        <div class="title" (click)="openDialog(p)">{{getProductLang(p).nameLong}}</div>
        <div class="fill-remaining-space"></div>
        <div class="signs">
          <div class="sign" *ngFor="let s of getProductFilters(p.filters)">
            <img class="circle" [src]="s.image" matTooltip="{{s.name | translate}}"/>
          </div>
        </div>
      </div>
      <div class="description" (click)="openDialog(p)">{{getProductLang(p).inhalt}}</div>
      <div class="price-row">
        <div class="price">
          {{p.price | currencyNumber:'CHF':2}}
        </div>
        <div class="basket-action" [ngClass]="{active: p.hover}">
          <button mat-icon-button color="primary" (click)="addToBasket(p)" (mouseenter)="p.hover = true" (mouseleave)="p.hover = false">
            <!-- <mat-icon aria-label="Basket" svgIcon="shopping-cart-black" *ngIf="!p.hover"></mat-icon>
            <mat-icon aria-label="Basket" svgIcon="shopping-cart-white" *ngIf="p.hover"></mat-icon> -->

            <svg id="Gruppe_38" data-name="Gruppe 38" xmlns="http://www.w3.org/2000/svg" width="17" height="13.836"
              viewBox="0 0 17 13.836">
              <path id="Pfad_28" data-name="Pfad 28"
                d="M151.61,62.595a.539.539,0,0,0,.011.109c.664,1.667,1.323,3.337,2,5a.482.482,0,0,0,.376.192c2.268.012,4.536,0,6.8.016a.416.416,0,0,0,.462-.324c.31-.815.639-1.623.963-2.433.336-.843.675-1.685,1.024-2.557Zm-5.356-2.145v1.063c.636,0,1.255.023,1.87-.009a.541.541,0,0,1,.611.4c1.121,2.6,2.264,5.188,3.388,7.786a.48.48,0,0,0,.523.349c2.7-.013,5.4-.008,8.093-.009.123,0,.246-.01.374-.016V68.966h-.439q-3.249,0-6.5,0c-.4,0-.9.12-1.185-.069s-.36-.7-.523-1.066q-1.553-3.541-3.109-7.081c-.052-.117-.179-.283-.275-.286C148.151,60.441,147.215,60.45,146.254,60.45ZM155.816,72.7a1.6,1.6,0,1,0-1.6,1.586A1.619,1.619,0,0,0,155.816,72.7Zm2.13-.008a1.6,1.6,0,1,0,1.6-1.59A1.619,1.619,0,0,0,157.945,72.689Z"
                transform="translate(-146.254 -60.448)" fill="#fff" />
              <path id="Pfad_29" data-name="Pfad 29"
                d="M216.691,88.68h11.644c-.349.872-.687,1.714-1.024,2.557-.323.81-.653,1.618-.963,2.433a.416.416,0,0,1-.462.324c-2.268-.013-4.536,0-6.8-.016a.482.482,0,0,1-.376-.192c-.68-1.661-1.34-3.33-2-5A.539.539,0,0,1,216.691,88.68Zm1.518,1.056c.418,1.042.811,2.038,1.225,3.026a.414.414,0,0,0,.323.154c1.824.009,3.648,0,5.472.012a.347.347,0,0,0,.386-.261c.29-.755.6-1.5.9-2.254.086-.215.165-.434.256-.676Z"
                transform="translate(-211.335 -86.533)" />
              <path id="Pfad_30" data-name="Pfad 30"
                d="M146.254,60.45c.961,0,1.9-.009,2.831.013.1,0,.223.169.275.286q1.562,3.537,3.109,7.081c.162.37.238.877.523,1.066s.781.068,1.185.069q3.249,0,6.5,0h.439v1.047c-.128.006-.251.016-.374.016-2.7,0-5.4,0-8.093.009a.48.48,0,0,1-.523-.349c-1.124-2.6-2.266-5.187-3.388-7.786a.541.541,0,0,0-.611-.4c-.616.032-1.234.009-1.87.009Z"
                transform="translate(-146.254 -60.448)" />
              <path id="Pfad_31" data-name="Pfad 31"
                d="M233.2,202.121a1.6,1.6,0,1,1-1.591-1.6A1.62,1.62,0,0,1,233.2,202.121Zm-1.588-.537a.531.531,0,1,0,.523.539A.537.537,0,0,0,231.608,201.584Z"
                transform="translate(-223.635 -189.871)" />
              <path id="Pfad_32" data-name="Pfad 32"
                d="M300,202.115a1.6,1.6,0,1,1,1.594,1.594A1.62,1.62,0,0,1,300,202.115Zm1.593.533a.531.531,0,1,0-.528-.534A.536.536,0,0,0,301.595,202.647Z"
                transform="translate(-288.311 -189.873)" />
              <path id="Pfad_33" data-name="Pfad 33"
                d="M236.651,102.563h8.559c-.092.242-.171.46-.256.676-.3.751-.607,1.5-.9,2.254a.347.347,0,0,1-.386.261c-1.824-.009-3.648,0-5.472-.012a.414.414,0,0,1-.323-.154C237.462,104.6,237.069,103.6,236.651,102.563Z"
                transform="translate(-229.777 -99.361)" fill="#fff" />
              <path id="Pfad_34" data-name="Pfad 34" d="M244.548,214.482a.531.531,0,1,1-.54.522A.536.536,0,0,1,244.548,214.482Z"
                transform="translate(-236.575 -202.769)" fill="#fff" />
              <path id="Pfad_35" data-name="Pfad 35" d="M314.537,215.544a.531.531,0,1,1,.536-.526A.536.536,0,0,1,314.537,215.544Z"
                transform="translate(-301.254 -202.77)" fill="#fff" />
            </svg>

          </button>
        </div>
      </div>
    </div>
  </div>
</div>
