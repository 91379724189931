import { Component, OnInit, OnDestroy } from '@angular/core';
import { Address } from 'src/app/models/address.model';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Profile } from 'src/app/models/profile.model';
import { CreditCard } from 'src/app/models/credit-card.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  address: Address = new Address();
  saving = false;
  error = false;
  profile$: any;
  profile: Profile;
  profileLoaded = false;
  loadingProfile = false;
  card: CreditCard = new CreditCard();

  constructor(
    private auth: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getProfile();
    this.profile$ = this.auth.profile.subscribe((p) => {
      this.mapProfileToAddress(p);
    });
  }

  ngOnDestroy() {
    this.profile$.unsubscribe();
  }

  getProfile() {
    this.error = false;
    this.loadingProfile = true;
    this.auth.getProfile().subscribe((p) => {
      this.loadingProfile = false;
      this.profileLoaded = true;
    }, (err) => {
      this.error = true;
      this.loadingProfile = false;
    });
  }

  mapProfileToAddress(profile) {
    this.address = profile;
  }

  saveProfile() {
    this.error = false;
    this.saving = true;
    this.auth.saveProfile(this.address).subscribe(
      (resp) => {
        this.saving = false;
      }, (err) => {
        this.error = true;
        this.saving = false;
      }
    );
  }

}
