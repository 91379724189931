import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, pattern: string = 'EE d. MMMM yyyy, HH:mm'): any {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    if (!value) { return; }
    // if (value.slice(-1) !== 'Z') {
    //   value = value + 'Z';
    // }
    return datePipe.transform(value, pattern);
  }


}
