import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { Restaurant } from '../models/restaurant.model';
import { ShopService } from '../services/shop.service';
import { OpenHour } from '../models/open-hour.model';
import { NavigationService } from '../services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '../models/order.model';
import { ShopQueryParams } from '../models/shop-query-params.model';
import { TranslateService } from '@ngx-translate/core';
import { Category } from '../models/category.model';
import { Meta, Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { RoutingService } from '../services/routing.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, OnDestroy, AfterViewChecked {

  restaurants$: any;
  restaurants: Array<Restaurant> = [];
  restaurant$: any;
  restaurant: Restaurant = null;
  openHours$: any;
  openHours: Array<OpenHour> = [];
  route$: any;
  shopQueryParams: ShopQueryParams = {
    restaurantId: null,
    orderType: null,
    col1: null,
    col2: null,
    lang: null,
    categoryId: null
  };
  shopQueryParams$: any;
  category: Category = null;
  category$: any;
  col1$: any;
  formatedPhoneNumber = null;

  constructor(
    private shopService: ShopService,
    private nav: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private meta: Meta,
    private title: Title,
  ) {

  }

  ngOnInit() {
    setTimeout(() => {
      console.log(this.restaurant)
    }, 2000);
    console.log('Init shop');
    this.shopQueryParams$ = this.nav.shopQueryParams.subscribe((s) => {
      this.shopQueryParams = s;
      const sq = JSON.parse(JSON.stringify(s));
      if (s) {
        if (!this.shopService.order.value.orderItems) {
          sq.col2 = null;
        }
        if (sq.col1 !== 'order-completed') {
          this.router.navigateByUrl(this.nav.paramsFromObject(sq, RoutingService.shopPrefix));
        }
      }

    });
    this.route$ = this.route.params.subscribe(
      (params) => {
        if (params.restaurantId) {
          this.shopService.initRestaurant(parseInt(params.restaurantId));
        } else {
          this.shopService.restaurant.next(null);
        }
        if (params.lang) {
          if (params.lang === 'de' || params.lang === 'en') {
            this.translate.use(params.lang);
          }
        }
        if (params.orderType) {
          this.shopService.orderType.next(params.orderType);
        } else {
          this.shopService.orderType.next(null);
        }
        if (params.col1) {
          this.nav.col1.next(params.col1);
        } else {
          this.nav.col1.next(null);
        }
        if (params.col2) {
          if (this.shopService.order.value.orderItems) {
            this.nav.col2.next(params.col2);
          } else {
            this.nav.col2.next(null);
          }
        } else {
          this.nav.col2.next(null);
        }
        this.nav.shopQueryParams.next(params);
      }
    );
    this.category$ = this.shopService.category.subscribe(
      (r) => {
        this.category = r;
        if (this.category) {
          const sq = JSON.parse(JSON.stringify(this.nav.shopQueryParams.value));
          sq.categoryId = this.category.categoryId;
          this.nav.shopQueryParams.next(sq);
          this.title.setTitle(
            this.translate.instant('TITLE_TAG_RESTAURANT_CATEGORY', {
              restaurantName: this.shopService.restaurant.value.name,
              categoryName: this.getCategoryName(this.category)
            })
          );
          this.meta.updateTag({
            name: 'description',
            content: this.translate.instant('META_DESCRIPTION_RESTAURANT_CATEGORY', {
              restaurantName: this.shopService.restaurant.value.name,
              categoryName: this.getCategoryName(this.category)
            })
          });
          this.router.navigateByUrl(this.nav.paramsFromObject(sq, RoutingService.shopPrefix));
        }
      }
    );
    this.shopService.getRestaurants().subscribe(arg => { });
    this.shopService.getOpenHours().subscribe(arg => { });
    this.restaurants$ = this.shopService.restaurants.subscribe(
      (r) => {
        this.restaurants = r;
        if (this.shopQueryParams.restaurantId) {
          this.shopService.initRestaurant(parseInt(this.shopQueryParams.restaurantId));
        }
      }
    );
    this.restaurant$ = this.shopService.restaurant.subscribe(
      (r) => {
        this.restaurant = r;
        this.handleSEO();
      }
    );
    this.openHours$ = this.shopService.openHours.subscribe(
      (r) => {
        this.openHours = r;
      }
    );
    this.col1$ = this.nav.col1.subscribe((col1) => {
      this.handleSEO();
    });

    /* const q = JSON.parse(JSON.stringify(this.nav.shopQueryParams.value));
    this.nav.col1.next('order-completed');
    q.orderType = null;
    q.col1 = 'order-completed';
    this.nav.shopQueryParams.next(q);
    this.router.navigateByUrl(this.nav.paramsFromObject(q, RoutingService.shopPrefix)); */
  }

  ngAfterViewChecked() {
    this.handleSEO();
  }

  ngOnDestroy() {
    this.restaurant$.unsubscribe();
    this.restaurants$.unsubscribe();
    this.openHours$.unsubscribe();
    this.route$.unsubscribe();
    this.shopQueryParams$.unsubscribe();
    this.category$.unsubscribe();
  }

  /*goToDiscover() {
    this.nav.col1.next('discoverpanasia');
    const q = JSON.parse(JSON.stringify(this.nav.shopQueryParams.value));
    q.orderType = null;
    q.col1 = 'discoverpanasia';
    this.nav.shopQueryParams.next(q);
    this.router.navigateByUrl(this.nav.paramsFromObject(q, RoutingService.shopPrefix));
   //this.router.navigateByUrl('discoverpanasia');
    console.log(q);
  }*/

  handleSEO() {
    const col1 = this.nav.col1.value;
    if (!col1) {
      this.title.setTitle(this.translate.instant('TITLE_TAG_SHOP'));
      this.meta.updateTag({ name: 'description', content: this.translate.instant('META_DESCRIPTION_SHOP') });
    }
    if (this.restaurant && !col1) {
      // Set default restaruant tags
      this.title.setTitle(
        this.translate.instant('TITLE_TAG_RESTAURANT', {
          restaurantName: this.restaurant.name
        })
      );
      this.meta.updateTag({
        name: 'description',
        content: this.translate.instant('META_DESCRIPTION_RESTAURANT', {
          restaurantName: this.restaurant.name
        })
      });
    }
    if (col1 === 'address' && this.restaurant) {
      // Delivery Tags
      this.title.setTitle(
        this.translate.instant('TITLE_TAG_RESTAURANT_DELIVERY', {
          restaurantName: this.restaurant.name
        })
      );
      this.meta.updateTag({
        name: 'description',
        content: this.translate.instant('META_DESCRIPTION_RESTAURANT_DELIVERY', {
          restaurantName: this.restaurant.name
        })
      });
    }
    if (col1 === 'categories' && this.restaurant && !this.category) {
      // Categories Tags
      this.title.setTitle(
        this.translate.instant(
          (this.shopService.orderType.value === 'takeout' ? 'TITLE_TAG_RESTAURANT_TAKEOUT_CATEGORIES' : 'TITLE_TAG_RESTAURANT_DELIVERY_CATEGORIES'),
          {
            restaurantName: this.restaurant.name,
          }
        )
      );
      this.meta.updateTag({
        name: 'description',
        content: this.translate.instant(
          (this.shopService.orderType.value === 'takeout' ? 'META_DESCRIPTION_RESTAURANT_TAKEOUT_CATEGORIES' : 'META_DESCRIPTION_RESTAURANT_DELIVERY_CATEGORIES'), {
          restaurantName: this.restaurant.name
        })
      });
    }
    if (col1 === 'menu' && this.restaurant) {
      // MENU Tags
      this.title.setTitle(
        this.translate.instant(
          'TITLE_TAG_RESTAURANT_MENU',
          {
            restaurantName: this.restaurant.name,
          }
        )
      );
      this.meta.updateTag({
        name: 'description',
        content: this.translate.instant(
          'META_DESCRIPTION_RESTAURANT_MENU', {
          restaurantName: this.restaurant.name
        })
      });
    }
    if (col1 === 'table-reservation' && this.restaurant) {
      // TABLE_RESERVATION Tags
      this.title.setTitle(
        this.translate.instant(
          'TITLE_TAG_RESTAURANT_TABLE_RESERVATION',
          {
            restaurantName: this.restaurant.name,
          }
        )
      );
      this.meta.updateTag({
        name: 'description',
        content: this.translate.instant(
          'META_DESCRIPTION_RESTAURANT_TABLE_RESERVATION', {
          restaurantName: this.restaurant.name
        })
      });
    }
    if (col1 === 'gallery' && this.restaurant) {
      // Categories Tags
      this.title.setTitle(
        this.translate.instant(
          'TITLE_TAG_RESTAURANT_GALLERY',
          {
            restaurantName: this.restaurant.name,
          }
        )
      );
      this.meta.updateTag({
        name: 'description',
        content: this.translate.instant(
          'META_DESCRIPTION_RESTAURANT_GALLERY', {
          restaurantName: this.restaurant.name
        })
      });
    }
  }

  selectRestaurant(restaurant: Restaurant) {
    this.nav.col1.next(0);
    this.nav.col2.next(0);
    const sq = JSON.parse(JSON.stringify(this.shopQueryParams));
    sq.restaurantId = restaurant === this.restaurant ? null : restaurant.restaurantId;
    sq.orderType = null;
    sq.col1 = null;
    sq.col2 = null;
    sq.lang = this.translate.currentLang;
    sq.categoryId = null;
    this.nav.shopQueryParams.next(sq);
    this.shopService.order.next(new Order());
    this.shopService.openDateTimes.next(null);
    this.shopService.orderType.next(null);
    this.shopService.selectedDateTime.next(null);
    this.shopService.category.next(null);
    this.router.navigateByUrl(this.nav.paramsFromObject(sq, RoutingService.shopPrefix));
    this.formatedPhoneNumber = restaurant.phoneNumber ? restaurant.phoneNumber.replace('(0)', '') : null;
  }

  restaurantOpenHours(type) {
    const oh = this.openHours.find(o => o.restaurantId === this.restaurant.restaurantId);
    // start monday, not sunday (sorting)
    const correctOrder = [1, 2, 3, 4, 5, 6, 0];
    const times = [];
    let mergedTimes = [];
    if (oh && type === 'regular') {
      correctOrder.forEach((c) => {

        // 0 = speisekarte zeiten
        oh[type][0].times.forEach(t => {
          if (c === t.weekDayNr) {
            let exist = false;
            times.forEach((time) => {
              if (time.weekDayNr === t.weekDayNr) {
                exist = true;
                time.times.push({ openFrom: t.openFrom, openTo: t.openTo });
              }
            });
            if (!exist) {
              t.times = [];
              t.times.push({ openFrom: t.openFrom, openTo: t.openTo });
              times.push(t);
            }
          }
        });
      });
      let m = times[0];
      m.fromDay = times[0].weekDayNr;
      let temp = times[0].weekDayNr;
      times.forEach((t) => {
        if (m.openFrom === t.openFrom && m.openTo === t.openTo && this.allSubTimesSame(m, t)) {
          if (temp + 1 === t.weekDayNr) {
            // mergedTimes.push(m);
            m.toDay = t.weekDayNr;
            m.times = t.times;
            temp = t.weekDayNr;
          } else {
            temp = t.weekDayNr;
            m = t;
            m.fromDay = t.weekDayNr;
            m.toDay = t.weekDayNr;
            m.times = t.times;
            mergedTimes.push(m);
          }
          // mergedTimes.push(m);
          // m.toDay = t.weekDayNr;
        } else {
          // mergedTimes.push(m);
          temp = t.weekDayNr;
          m = t;
          m.fromDay = t.weekDayNr;
          m.toDay = t.weekDayNr;
          m.times = t.times;
          mergedTimes.push(m);
        }
      });
    }
    if (oh && type === 'special') {
      mergedTimes = oh[type][0] ? oh[type][0].times : [];
      mergedTimes = mergedTimes.filter(m => m.clientTypeId === 2);
      mergedTimes = mergedTimes.filter(m => moment().isBefore(moment(m.specialDate)));
    }
    return oh ? mergedTimes : null;
  }

  allSubTimesSame(m, t) {
    let same = true;
    for (let i = 0; i < m.times.length; i++) {
      const mt = m.times[i];
      if (mt.openFrom === t.times[i].openFrom && mt.openTo === t.times[i].openTo) {
        // ok
      } else {
        same = false;
      }
    }

    return same;
  }

  formatTime(time) {
    let formatted = time.substr(0, time.length - 3);
    if (formatted === '23:59') {
      formatted = '00:00';
    }
    if (this.translate.currentLang === 'en') {
      const hour = parseInt(formatted.substr(0, 2));
      if (hour < 12) {
        formatted = this.zeroShift(hour).toString() + formatted.substr(2, 5) + 'am';
      }
      if (hour > 11) {
        formatted = this.zeroShift(hour - 12).toString() + formatted.substr(2, 5) + 'pm';
      }
    }
    return formatted;
  }

  zeroShift(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }



  getCategoryName(category: Category) {
    return category.categoryLanguages.find(o => o.languageKuerzel === this.translate.currentLang).name;
  }

  openGoogleMaps(lat, lng) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
  }

  openEmail(email) {
    window.open(`mailto:${email}`);
  }

  goToXMas() {
    window.open('http://vouchers.twospice.ch/', '_blank');
  }

}
