<div class="basket">
  <div class="order-items">
    <div class="order-item" *ngFor="let o of order.orderItems;trackBy: trackByFn">
      <div class="product-name" [style.color]="o.isAvailable ? 'black' : '#CC2538'">
        {{o.itemName}}
      </div>
      <div class="right">
        <div class="item-ctrls" *ngIf="step < 4">
          <button mat-icon-button color="primary" (click)="minusBasketItemQuantity(o)">
            <mat-icon aria-label="Basket">remove</mat-icon>
          </button>
          <input type="number" [(ngModel)]="o.quantity" min="1" (ngModelChange)="changeBasketItemQuantity(o)">
          <button mat-icon-button color="primary" (click)="plusBasketItemQuantity(o)">
            <mat-icon aria-label="Basket">add</mat-icon>
          </button>
        </div>
        <div style="display: inline-block;">CHF</div>
        <div class="price">
          {{o.itemPrice | number: '1.2-2' }}
        </div>
        <div class="delete-item" *ngIf="step < 4">
          <button mat-icon-button color="primary" (click)="removeBasketItem(o)">
            <!-- <mat-icon aria-label="Basket" svgIcon="close"></mat-icon> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="10.868" height="10.868" viewBox="0 0 10.868 10.868">
              <g id="Gruppe_44" data-name="Gruppe 44" transform="translate(0 14.104)">
                <g id="Gruppe_43" data-name="Gruppe 43" transform="translate(0 -14.105)">
                  <path id="Pfad_36" data-name="Pfad 36"
                    d="M-2.956,0l-4.5,4.5-4.5-4.5-.917.944,4.5,4.5-4.5,4.5.917.917,4.5-4.5,4.5,4.5.944-.917-4.5-4.5,4.5-4.5Z"
                    transform="translate(12.88)" fill="#641b65" />
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="order-item" *ngIf="order.deliveryCosts">
        <div class="product-name" [style.color]="'black'">
            {{ 'DELIVERY_COSTS' | translate }}
        </div>
        <div class="right">
            <div class="item-ctrls" *ngIf="step < 4">
            </div>
        <div style="display: inline-block;">CHF</div>
        <div class="price delivery">
          {{order.deliveryCosts | number: '1.2-2' }}
        </div>
      </div>
    </div>
  </div>
  <div class="total">
    <div class="label">
      <b>{{ 'TOTAL' | translate }}</b>
      <!-- {{ 'INCL_MWST' | translate }} -->
    </div>
    <div style="display: inline-block;">CHF</div>
    <div class="value" [style.marginRight]="step < 4 ? '30px' : '0'">
      {{ order.bruttoTotal | number: '1.2-2' }}
    </div>
  </div>
  <div class="errors" >
    <div class="input-error" *ngIf="hasUnavailableOrderItems()" style="margin-bottom: 16px;">
      <span>{{ 'UNAVAILABLE_ORDER_ITEMS_ERROR' | translate }}</span>
    </div>
  </div>
</div>
