<div class="app-outest" [ngClass]="{'basket-navigation-open': this.order?.orderItems?.length > 0}">
  <div class="basket-navigation">
    <app-basket-navigation></app-basket-navigation>
  </div>
  <!-- 3 Column Design -->
  <div class="columns">
    <div class="col col0" [ngClass]="{hidden: isMobile() && (col1 || col2)}">
      <app-navigation></app-navigation>
      <div class="router">
        <router-outlet></router-outlet>
      </div>
      <div class="footer">
        <app-footer></app-footer>
      </div>
    </div>
    <div class="col1and2" >
      <app-navigation *ngIf="isMobile()"></app-navigation>
      <div class="background-img-2col" [ngClass]="{'transform-1col': col1, 'transform-nocol': col2, 'basket-hidden': this.order?.orderItems?.length > 0 && !col2}"></div>
      <div class="col col1" [ngClass]="{'enter-animation': col1, hidden: isMobile() && col2}" *ngIf="col1">
        <div class="order">
          <app-delivery-address *ngIf="col1 === 'address'"></app-delivery-address>
          <app-categories *ngIf="col1 === 'categories'"></app-categories>
          <app-menu *ngIf="col1 === 'menu'"></app-menu>
          <app-table-reservation *ngIf="col1 === 'table-reservation'"></app-table-reservation>
          <app-gallery *ngIf="col1 === 'gallery'"></app-gallery>
          <app-order-completed *ngIf="col1 === 'order-completed'"></app-order-completed>
          <!--<app-initiatives *ngIf="col1 === 'initiatives'"></app-initiatives>-->
          <!--<app-discoverpanasia *ngIf="col1 === 'discoverpanasia'"></app-discoverpanasia>-->
          <app-wp-menu *ngIf="col1 === 'wp-menu'"></app-wp-menu>
          <app-app-marketing *ngIf="col1 === 'app-marketing'"></app-app-marketing>
        </div>
      </div>
      <div class="col col2" [ngClass]="{'enter-animation': col2}">
        <app-basket *ngIf="col2 === 'basket'"></app-basket>
      </div>
    </div>
  </div>
</div>
<div class="cookies" *ngIf="showCookies">
  <span [innerHTML]="'COOKIES-TEXT' | translate"></span>
  <mat-icon (click)="closeCookies()">close</mat-icon>
</div>


