<div class="fold-item" [ngClass]="{big: size === 'big', normal: size === 'normal', open: isOpen}">
  <div matRipple class="trigger-area" (click)="onTrigger()" (keyup.enter)="onTrigger()" tabindex="0">
    <div class="title">{{ oTitle ? oTitle : title}}</div>
    <span class="fill-remaining-space"></span>
    <div class="right-display-value" *ngIf="rightDisplayValue">
      {{rightDisplayValue}}
    </div>
    <div class="arrow" *ngIf="content !== 'logout'">
      <!-- <mat-icon>{{!isOpen ? 'arrow_downward' : 'arrow_upward'}}</mat-icon> -->
      <!-- <mat-icon [ngClass]="{invert: isOpen}">arrow_downward</mat-icon> -->
      <!-- <img [ngClass]="{invert: isOpen}" src="./assets/icons/arrow_downward.svg"> -->
      <svg *ngIf="content !== 'text'" [ngClass]="{invert: isOpen}" xmlns="http://www.w3.org/2000/svg" height="24"
        viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
      </svg>
    </div>
  </div>
  <div class="content-area" *ngIf="isOpen">
    <app-restaurant-content *ngIf="content === 'restaurant'"></app-restaurant-content>
    <app-category-content *ngIf="content === 'category'"></app-category-content>
    <app-time-content [type]="content" *ngIf="content === 'takeout-time' || content === 'delivery-time'"
      [isOpen]="isOpen"></app-time-content>
    <app-login *ngIf="content === 'login'" (overrideTitle)="onOverrideTitle($event)"></app-login>
    <app-registration *ngIf="content === 'registration'"></app-registration>
    <app-profile *ngIf="content === 'profile'"></app-profile>
    <app-change-password *ngIf="content === 'changePassword'"></app-change-password>
    <app-reset-password *ngIf="content === 'resetPassword'"></app-reset-password>
    <app-help *ngIf="content === 'help'"></app-help>
    <!--<div *ngIf="content === 'text'">
      <div class="menu">
        <div>
          <p style="font-weight: bold;">{{'READY_TO_HEAT_TEXT1' | translate}}</p>
          <p>{{'READY_TO_HEAT_TEXT2' | translate}}</p>
        </div>
        <div class="item" matRipple style="margin-bottom: 15px;"><div class="inner" (click)="openURL('https://www.nooba.ch/shop/restaurantId/1/lang/de/orderType/takeout/col1/categories/categoryId/0d019444-06aa-4f4c-8c28-617a0155e6b7')">TAKE OUT - {{'READY_TO_HEAT_TEXT3' | translate}}</div></div>
        <div class="item" matRipple><div class="inner" (click)="openURL('https://www.nooba.ch/shop/restaurantId/1/lang/de/orderType/delivery/col1/address')">DELIVERY - {{'READY_TO_HEAT_TEXT3' | translate}}</div>
        </div>
      </div>
    </div>-->
  </div>
</div>
