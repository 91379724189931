<div class="content-padding">
  <h2 *ngIf="orderType === 'delivery'">{{ 'ORDER' | translate }}</h2>
  <h2 *ngIf="orderType === 'takeout'">{{ 'TAKEOUT' | translate }}</h2>
  <div class="categories">
    <div class="loading" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div class="category" *ngFor="let r of categories;trackBy: trackByFn">
      <app-fold-item [title]="getCategoryName(r)" [isOpen]="category === r" size="normal" content="category"
        (triggerItem)="selectCategory(r)"></app-fold-item>
    </div>
  </div>
  <!-- <div class="additional-info">
    <h4>{{ 'IMPORTANT_INFORMATIONS' | translate}}</h4>
    <p>{{ 'IMPORTANT_INFORMATIONS_P' | translate}}</p>
  </div> -->
  <div class="sign-explanation">
    <h4>{{ 'SIGN_EXPLANATION' | translate}}</h4>
    <div class="signs">
      <div class="sign" *ngFor="let s of signs">
        <img class="circle" [src]="s.image"/>
        <div class="name">{{s.name | translate}}</div>
      </div>
    </div>
    <div>
      <p style="font-size: 13px;">{{ 'DECLARATION' | translate }}</p>
    </div>
  </div>
</div>
