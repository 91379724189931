<div class="cc-form">
  <form name="card-form" (ngSubmit)="cardForm.form.valid && onSubmit(cardForm)" id="cardForm" #cardForm="ngForm"
    novalidate>
    <div class="saved-cards">
      <div class="selection" *ngIf="type === 'checkout' && cards?.length > 0">
        <mat-form-field class="block" appearance="outline">
          <mat-label>{{ 'SELECT_SAVED_CREDIT_CARD' | translate}}</mat-label>
          <mat-select [(ngModel)]="card.innoCardPaymentId" name="innoCardPaymentId">
            <mat-option *ngFor="let c of cards" [value]="c.innoCardPaymentId">
              {{ c.cardType + ' ' + c.cardNumber }}
            </mat-option>
            <mat-option [value]="null" *ngIf="card.innoCardPaymentId">
              {{ 'USE_ANOTHER_CREDIT_CARD' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="manager" *ngIf="type === 'add-card'">
        <h5>{{ 'SAVED_CREDIT_CARDS' | translate }}</h5>
        <div class="seperator"></div>
        <div class="card" *ngFor="let c of cards">
          <div class="name">{{c.cardType}}<span *ngIf="c.cardNumber"> - {{c.cardNumber}}</span></div>
          <div class="delete">
            <button type="button" mat-icon-button color="primary" (click)="deleteCard(c)">
              <!-- <mat-icon aria-label="Basket" svgIcon="close"></mat-icon> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="10.868" height="10.868" viewBox="0 0 10.868 10.868">
                <g id="Gruppe_44" data-name="Gruppe 44" transform="translate(0 14.104)">
                  <g id="Gruppe_43" data-name="Gruppe 43" transform="translate(0 -14.105)">
                    <path id="Pfad_36" data-name="Pfad 36"
                      d="M-2.956,0l-4.5,4.5-4.5-4.5-.917.944,4.5,4.5-4.5,4.5.917.917,4.5-4.5,4.5,4.5.944-.917-4.5-4.5,4.5-4.5Z"
                      transform="translate(12.88)" fill="#641b65" />
                  </g>
                </g>
              </svg>

            </button>
          </div>
        </div>
        <button type="button" disableRipple class="add-card-toggler" (click)="toggleCardForm()" mat-button>{{
          'ADD_CREDIT_CARD' | translate }}</button>
      </div>

    </div>
    <div *ngIf="(type === 'checkout' && !card.innoCardPaymentId) || showCardForm">

      <div class="loading" *ngIf="saving">
        <mat-spinner></mat-spinner>
      </div>

      <div *ngIf="!saving">
        <!-- <div>
            <mat-form-field class="block" appearance="outline">
              <mat-label>{{ 'CREDIT_CARD_HOLDER' | translate }}</mat-label>
              <input matInput type="text" name="cardHolder" placeholder="{{ 'CREDIT_CARD_HOLDER' | translate }}"
                [(ngModel)]="card.cardHolder" #holderInputEl #cardHolder="ngModel" required>
              <mat-error *ngIf="cardForm.submitted && cardHolder.invalid">
                <div *ngIf="cardHolder.errors.required">{{ 'ENTER_VALID_CARD_HOLDER' | translate}}</div>
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="block" appearance="outline">
              <mat-label>{{ 'CREDIT_CARD_NUMBER' | translate }}</mat-label>
              <input matInput type="text" name="cardNumber" placeholder="{{ 'CREDIT_CARD_NUMBER' | translate }}"
                [(ngModel)]="card.cardNumber" (ngModelChange)="onChangeCardNumber()" #numberInputEl #cardNumber="ngModel"
                required>
              <mat-error *ngIf="cardForm.submitted && cardNumber.invalid">
                <div *ngIf="cardNumber.errors.required">{{ 'ENTER_VALID_CREDIT_CARD_NUMBER' | translate}}</div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="valid-until">
            <mat-form-field class="month" appearance="outline">
              <mat-label>{{ 'EXPIRY_MONTH' | translate }}</mat-label>
              <input matInput type="number" max="12" min="1" name="expiryMonth" placeholder="{{ 'EXPIRY_MONTH' | translate }}"
                [(ngModel)]="card.expiryMonth" (ngModelChange)="onChangeExpiryMonth()" #monthInputEl #expiryMonth="ngModel"
                required>
              <mat-error *ngIf="cardForm.submitted && expiryMonth.invalid">
                <div *ngIf="expiryMonth.errors.required">{{ 'ENTER_VALID_EXPIRY_MONTH' | translate}}</div>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="year" appearance="outline">
              <mat-label>{{ 'EXPIRY_YEAR' | translate }}</mat-label>
              <input matInput type="number" name="expiryYear" placeholder="{{ 'EXPIRY_YEAR' | translate }}"
                [(ngModel)]="card.expiryYear" (ngModelChange)="onChangeExpiryYear()" #yearInputEl #expiryYear="ngModel" required>
              <mat-error *ngIf="cardForm.submitted && expiryYear.invalid">
                <div *ngIf="expiryYear.errors.required">{{ 'ENTER_VALID_EXPIRY_YEAR' | translate}}</div>
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="block" appearance="outline">
              <mat-label>{{ 'CVV' | translate }}</mat-label>
              <input matInput type="text" name="cardSecurityCode" (ngModelChange)="onChangeCardSecurityCode()"
                placeholder="{{ 'CVV' | translate }}" [(ngModel)]="card.cardSecurityCode" #cvvInputEl #cardSecurityCode="ngModel"
                required>
              <mat-error *ngIf="cardForm.submitted && cardSecurityCode.invalid">
                <div *ngIf="cardSecurityCode.errors.required">{{ 'ENTER_VALID_CVV' | translate}}</div>
              </mat-error>
            </mat-form-field>
          </div> -->
        <div class="payment-type-selection">
          <mat-radio-group [(ngModel)]="paymentType" name="paymentType" (ngModelChange)="onPaymentTypeChange()" >
            <ng-container *ngFor="let p of paymentMethods">
              <mat-radio-button color="primary" class="block" style="margin:5px 0px;" [value]="p.Name">
                {{p.Name}}<span style="font-size: 10px;" *ngIf="p.Description"> ({{p.Description}})</span>
              </mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>

      </div>

      <div class="card-errors">
        <div class="input-error" *ngIf="error && type === 'add-card'" >
          {{ 'SAVE_CARD_ERROR' | translate}}
        </div>
        <div class="input-error" *ngIf="error && type === 'checkout'">
          {{ 'PAY_CARD_ERROR' | translate}}
        </div>
      </div>


      <button *ngIf="type === 'add-card'" type="submit" class="next" mat-flat-button color="primary" [disabled]="saving || !paymentType"
        style="margin-bottom: 16px;">
        {{ 'SAVE_CREDIT_CARD' | translate }}</button>
    </div>
  </form>
</div>