import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-fold-item',
  templateUrl: './fold-item.component.html',
  styleUrls: ['./fold-item.component.scss']
})
export class FoldItemComponent implements OnInit {

  oTitle: any;

  @Input() title: string;
  @Input() isOpen: boolean;
  @Input() size: string;
  @Input() content: string;
  @Input() rightDisplayValue: string;

  @Output() triggerItem: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onTrigger() {
    this.oTitle = null;
    if (this.content === 'logout') {
      this.triggerItem.emit('logout');
    } else {
      this.triggerItem.emit(this.isOpen ? 'close' : 'open');
    }
  }

  onOverrideTitle(oTitle) {
    this.oTitle = oTitle;
  }

  openURL(url) {
    window.open(url, '_self');
  }

}
