import { Component, OnInit, OnDestroy } from '@angular/core';
import { Product } from 'src/app/models/product.model';
import { ShopService } from 'src/app/services/shop.service';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2 } from 'angulartics2';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from 'src/app/product-dialog/product-dialog.component';

@Component({
  selector: 'app-category-content',
  templateUrl: './category-content.component.html',
  styleUrls: ['./category-content.component.scss']
})
export class CategoryContentComponent implements OnInit, OnDestroy {

  product$: any;
  products: Array<Product> = [];
  loadingProducts = true;

  constructor(
    private shopService: ShopService,
    private translate: TranslateService,
    private angulartics2: Angulartics2,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadingProducts = true;
    this.shopService.getProducts().subscribe((p) => { this.loadingProducts = false; });
    this.product$ = this.shopService.products.subscribe((p) => {
      this.products = p;
    });
  }

  ngOnDestroy() {
    this.product$.unsubscribe();
    this.shopService.products.next(null);
  }

  getProductLang(p: Product) {
    return p.productLanguages.find(o => o.languageKuerzel === this.translate.currentLang);
  }

  addToBasket(p: Product) {
    p.hover = true;
    this.angulartics2.eventTrack.next({ action: 'addToBasket' });
    this.shopService.addProductToBasket(p);
    setTimeout(() => {
      p.hover = false;
    }, 220);
  }

  openDialog(p: Product) {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      data: {
        product: p
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.addToBasket(res);
      }
    });
  }

  getProductFilters(filters) {
    if (!filters) { return []; }
    // filters separated through semicolon
    const splitted = filters.split(';');
    const signs = this.shopService.signs;
    const active = [];
    splitted.forEach(s => {
      signs.forEach(sign => {
        if (s === sign.filterId) {
          active.push(sign);
        }
      });
    });
    return active;
  }

}
