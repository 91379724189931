import { Component, OnInit } from '@angular/core';
import { Page } from '../models/page.model';
import { ShopService } from '../services/shop.service';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {

  pages$: any;
  page: Page = null;

  constructor(
    private shop: ShopService,
    private translate: TranslateService,
    private title: Title,
    private meta: Meta
  ) { }

  ngOnInit() {
    this.handleSEO();
    this.translate.onLangChange.subscribe(() => {
      this.title.setTitle(
        this.translate.instant('TITLE_TAG_IMPRINT')
      );
      this.handleSEO();
    });
    this.pages$ = this.shop.pages.subscribe(
      (pages) => {
        pages.forEach(c => {
          if (c.slug === 'impressum') {
            this.page = c;
          }
        });
      }
    );
  }

  handleSEO() {
    this.title.setTitle(
      this.translate.instant('TITLE_TAG_IMPRINT')
    );
    this.meta.updateTag({
      name: 'description',
      content: this.translate.instant(
        'META_DESCRIPTION_IMPRINT'
      )
    });
  }

}
