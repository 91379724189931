
<form name="address-form" (ngSubmit)="addressForm.form.valid && onSubmit(addressForm)" id="addressForm" #addressForm="ngForm" novalidate>
  <div class="salutation flex">
    <!-- <label id="sex-label">{{ 'SALUTATION' | translate }}</label>
    <span class="fill-remaining-space"></span> -->
    <mat-radio-group aria-labelledby="sex-label" name="sex" [(ngModel)]="address.sex" required>
      <mat-radio-button color="primary" [value]="0">{{ 'MRS' | translate }}</mat-radio-button>
      <mat-radio-button color="primary" [value]="1">{{ 'MR' | translate }}</mat-radio-button>
      <!-- <mat-radio-button [value]="null">{{ 'NO_SPECIFICATION' | translate }}</mat-radio-button> -->
    </mat-radio-group>
  </div>
  <div>
    <mat-form-field class="block" appearance="outline">
      <mat-label>{{ 'COMPANY' | translate }}</mat-label>
      <input matInput type="text" name="company" placeholder="{{ 'COMPANY' | translate }}" [(ngModel)]="address.company"
        #company="ngModel" >
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="block" appearance="outline">
      <mat-label>{{ 'FIRSTNAME' | translate }}</mat-label>
      <input matInput type="text" name="firstName" placeholder="{{ 'FIRSTNAME' | translate }}"
        [(ngModel)]="address.firstName" #firstName="ngModel" required>
      <mat-error *ngIf="addressForm.submitted && firstName.invalid">
        <div *ngIf="firstName.errors.required">{{ 'ENTER_VALID_FIRSTNAME' | translate}}</div>
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="block" appearance="outline">
      <mat-label>{{ 'LASTNAME' | translate }}</mat-label>
      <input matInput type="text" name="lastName" placeholder="{{ 'LASTNAME' | translate }}"
        [(ngModel)]="address.lastName" #lastName="ngModel" required>
      <mat-error *ngIf="addressForm.submitted && lastName.invalid">
        <div *ngIf="lastName.errors.required">{{ 'ENTER_VALID_LASTNAME' | translate}}</div>
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="block" appearance="outline" *ngIf="showAddressFields ? true : false">
      <mat-label>{{ 'STREET' | translate }}</mat-label>
      <input matInput type="text" name="street" placeholder="{{ 'STREET' | translate }}" [(ngModel)]="address.street"
        #street="ngModel" required [disabled]="disableStreet ? true : false">
      <mat-error *ngIf="addressForm.submitted && street.invalid">
        <div *ngIf="street.errors.required">{{ 'ENTER_VALID_STREET' | translate}}</div>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="zip-town">
    <mat-form-field class="zip" appearance="outline" *ngIf="showAddressFields ? true : false">
      <mat-label>{{ 'ZIP' | translate }}</mat-label>
      <input matInput type="text" minlength="4" maxlength="4" name="zip" placeholder="{{ 'ZIP' | translate }}" [(ngModel)]="address.zip"
        #zip="ngModel" required [disabled]="disableStreet ? true : false">
      <mat-error *ngIf="addressForm.submitted && zip.invalid">
        <div *ngIf="zip.errors.required">{{ 'ENTER_VALID_ZIP' | translate}}</div>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="town" appearance="outline" *ngIf="showAddressFields ? true : false">
      <mat-label>{{ 'TOWN' | translate }}</mat-label>
      <input matInput type="text" name="city" placeholder="{{ 'TOWN' | translate }}" [(ngModel)]="address.city"
        #city="ngModel" required [disabled]="disableStreet ? true : false">
      <mat-error *ngIf="addressForm.submitted && city.invalid">
        <div *ngIf="city.errors.required">{{ 'ENTER_VALID_TOWN' | translate}}</div>
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="block" appearance="outline">
      <mat-label>{{ 'EMAIL' | translate }}</mat-label>
      <input matInput type="email" name="email" placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="address.email"
        #email="ngModel" required email [pattern]="mailRegex">
      <mat-error *ngIf="addressForm.submitted && email.invalid">
        <div *ngIf="email.errors.required || email.errors.pattern">{{ 'ENTER_VALID_EMAIL' | translate}}</div>
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="block" appearance="outline" class="phone-input">
      <mat-label>{{ 'MOBILE' | translate }}</mat-label>
      <input matInput type="text" name="phone" placeholder="{{ 'MOBILE' | translate }}" [(ngModel)]="address.phone"
        #phone="ngModel" required [pattern]="phoneRegex">
      <mat-error *ngIf="addressForm.submitted && phone.invalid">
        <div *ngIf="phone.errors.required">{{ 'ENTER_VALID_MOBILE' | translate}}</div>
        <div *ngIf="phone.errors.pattern">{{ 'ENTER_VALID_MOBILE_PATTERN' | translate}}</div>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="datepicker" *ngIf="type === 'registration'">
    <mat-form-field appearance="outline">
      <mat-label>{{ ('BIRTH_DATE') | translate}}</mat-label>
      <input name="birthDate" [(ngModel)]="address.birthDate" matInput (click)="picker.open()" (ngModelChange)="onSelectDate()"
        placeholder="{{ ('BIRTH_DATE') | translate}}"
        [matDatepicker]="picker" [min]="getMinDate()" [max]="getMaxDate()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div *ngIf="type === 'checkout'">
    <mat-form-field class="block" appearance="outline" >
      <mat-label>{{ 'MESSAGE' | translate }}</mat-label>
      <input matInput type="text" name="message" placeholder="{{ 'MESSAGE' | translate }}" [(ngModel)]="address.message"
        #message="ngModel">
    </mat-form-field>
  </div>
  <div *ngIf="type === 'checkout'">
    <mat-form-field class="block" appearance="outline">
      <mat-label>{{ 'AMOUNT_PERSONS' | translate }}</mat-label>
      <mat-select [(ngModel)]="address.amountPersons" required name="amountPersons" #amountPersons="ngModel">
        <mat-option *ngFor="let n of cutlery" [value]="n.value">
          <div>{{n.display}}</div>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="addressForm.submitted && amountPersons.invalid">
        <div *ngIf="amountPersons.errors.required">{{ 'ENTER_VALID_AMOUNT_PERSONS' | translate}}</div>
      </mat-error>
    </mat-form-field>
    <!--<mat-form-field class="block" appearance="outline" >
      <mat-label>{{ 'AMOUNT_PERSONS' | translate }}</mat-label>
      <input matInput type="number" [min]="1" name="amountPersons" placeholder="{{ 'AMOUNT_PERSONS' | translate }}"
        [(ngModel)]="address.amountPersons" #amountPersons="ngModel" pattern="^[1-9][0-9]*$" required>
      <mat-error *ngIf="addressForm.submitted && amountPersons.invalid">
        <div *ngIf="amountPersons.errors.required || amountPersons.errors.pattern">{{ 'ENTER_VALID_AMOUNT_PERSONS' | translate}}</div>
      </mat-error>
    </mat-form-field>-->
  </div>
  <div *ngIf="type === 'registration'">
    <mat-form-field class="block" appearance="outline">
      <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
      <input matInput [type]="showPassword ? 'text' : 'password'" name="password" placeholder="{{ 'PASSWORD' | translate }}" [(ngModel)]="address.password"
        #password="ngModel" minlength="6" required>
        <button type="button" mat-button *ngIf="address.password" matSuffix mat-icon-button aria-label="Show Password" (click)="onShowPassword()">
          <mat-icon>{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="addressForm.submitted && password.invalid">
          <div *ngIf="password.errors.minlength">{{ 'PASSWORD_MINLENGTH_ERROR' | translate }}</div>
        </mat-error>
    </mat-form-field>
  </div>
  <!-- <div *ngIf="type === 'registration'">
    <mat-form-field class="block" appearance="outline">
      <mat-label>{{ 'REPEAT_PASSWORD' | translate }}</mat-label>
      <input matInput type="password" name="repeatPassword" placeholder="{{ 'REPEAT_PASSWORD' | translate }}" [(ngModel)]="address.repeatPassword"
        required>
    </mat-form-field>
  </div> -->
  <div *ngIf="type === 'profile'" style="margin-bottom: 24px;">
    <mat-checkbox name="newsletter" [(ngModel)]="address.infoMailAllowed" color="primary">{{ 'NEWSLETTER' | translate }}</mat-checkbox>
  </div>
  <div *ngIf="type !== 'profile'" style="margin-bottom: 24px;">
    <mat-checkbox name="agreement" required class="agreement" [(ngModel)]="address.agreement" color="primary" ><span [innerHTML]="'AGREEMENT' | translate"></span></mat-checkbox>
    <mat-error *ngIf="addressForm.submitted && address.agreement !== true">
      <div>{{'NOT_AGREED' | translate}}</div>
    </mat-error>
  </div>
    <p class="mat-error" *ngIf="addressForm.submitted && !address.sex">{{ 'MISSING_SEX_ERROR' | translate }}</p>
</form>


