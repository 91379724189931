<div class="content-padding">
  <div *ngIf="!product"> {{ 'PRODUCT_NOT_FOUND' | translate }}</div>
  <div *ngIf="product">
    <div class="productDescription" style="width: 50%;">
      <h3>{{ product.nameShort }}</h3>
    </div>
    <table class="nu">
      <tbody>
        <tr>
          <th style="text-align: left; color: #641B65;">
            {{ 'NUTRITIONINFO' | translate }}
          </th>
          <th style="text-align: right; color: #641B65;">
            {{ 'PER100G' | translate }}
          </th>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'ENERGY' | translate }}
          </td>
          <td style="text-align: right;">
            {{ product.energyKj }}kj ({{ product.energyKcal }} kcal)
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'GREASE' | translate }}
          </td>
          <td style="text-align: right;">
            {{ product.fat }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'ACIDS' | translate }}
          </td>
          <td style="text-align: right;">
            {{ product.saturatedFat }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'HYDRATES' | translate }}
          </td>
          <td style="text-align: right;">
            {{ product.carb }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'SUGAR' | translate }}
          </td>
          <td style="text-align: right;">
            {{ product.sugar }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'PROTEIN' | translate }}
          </td>
          <td style="text-align: right;">
            {{ product.protein }}g
          </td>
        </tr>
        <tr>
          <td class="infolabel infolabeltitle">
            {{ 'SALT' | translate }}
          </td>
          <td style="text-align: right;">
            {{ product.salt }}g
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="product">
    <div>
      <h3 style="color: #641B65;">
        {{ 'ALLERGENS' | translate}}
      </h3>
      <div>
        <p style="overflow-wrap: break-word;"> {{ addSpacesAfterComma(product.allergensText) }}</p>
      </div>
    </div>
    <div>
      <h3 style="color: #641B65;">
        {{ 'INGREDIENTS' | translate}}
      </h3>
      <div>
        <p class="ingredients" [innerHTML]="addSpacesAfterComma(product.ingredientsText)"></p>
      </div>
    </div>
  </div>
</div>
