import { Component, OnInit } from '@angular/core';
import { Address } from 'src/app/models/address.model';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  address: Address = new Address();
  registering = false;
  loggingIn = false;
  error = null;
  loginError = false;
  invalidSign = false;

  constructor(
    private auth: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  register() {
    this.error = false;
    this.invalidSign = false;
    if (this.address.password.indexOf('+') !== -1) {
      this.invalidSign = true;
      return;
    }
    this.registering = true;
    const l = this.translate.currentLang;
    if (l === 'de') { this.address.languageCode = 'de-CH'; }
    if (l === 'en') { this.address.languageCode = 'en-GB'; }
    this.auth.register(this.address).subscribe(
      (resp) => {
        this.registering = false;
        this.loggingIn = true;
        this.auth.login({ username: this.address.email, password: this.address.password }).subscribe(
          (res) => {
            this.loggingIn = false;
          }, (err) => {
            this.loginError = true;
            this.loggingIn = false;
          }
        );
      }, (err) => {
        this.error = err;
        this.registering = false;
      }
    );
  }

}
