import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user = {
    email: null,
    password: null
  };
  invalidCredentials = false;
  loggingIn = false;
  showForgotPassword = false;
  forgotPasswordSuccess = false;

  @Output() overrideTitle: EventEmitter<any> = new EventEmitter();

  constructor(
    private auth: AuthService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
  }

  login() {
    this.invalidCredentials = false;
    this.loggingIn = true;
    this.auth.login({ username: this.user.email, password: this.user.password }).subscribe(
      (resp) => {
        this.loggingIn = false;
      }, (err) => {
        console.log(err);
        this.invalidCredentials = true;
        this.loggingIn = false;
      }
    );
  }

  isValidEmail(email) {
    let isValid = false;
    if (email) {
      const mailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (mailRegex.test(email.toString())) {
        isValid = true;
      }
    }

    return isValid;
  }

  forgotPassword() {
    this.invalidCredentials = false;
    this.loggingIn = true;
    this.auth.forgotPasswordRequest({ email: this.user.email}).subscribe(
      (resp) => {
        this.forgotPasswordSuccess = true;
        this.loggingIn = false;
      }, (err) => {
        this.invalidCredentials = true;
        this.loggingIn = false;
      }
    );
  }

  secondaryAction() {
    this.showForgotPassword = !this.showForgotPassword;
    this.overrideTitle.emit(this.showForgotPassword ? this.translate.instant('FORGOT_PASSWORD') : null);

  }

  submit() {
    if (this.showForgotPassword) {
      this.forgotPassword();
    } else {
      this.login();
    }
  }

}
