import { Component, OnInit, Input } from '@angular/core';
import { ShopService } from 'src/app/services/shop.service';
import { Order, OrderItem } from 'src/app/models/order.model';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-basket-items',
  templateUrl: './basket-items.component.html',
  styleUrls: ['./basket-items.component.scss']
})
export class BasketItemsComponent implements OnInit {

  order: Order = null;
  order$: any;
  _timeout: any = null;

  @Input() step;

  constructor(
    private shop: ShopService
  ) { }

  ngOnInit() {
    this.order$ = this.shop.order.subscribe((o) => {
      this.order = o;
    });
  }

  minusBasketItemQuantity(orderItem: OrderItem) {
    if (orderItem.quantity === 1) {
      this.removeBasketItem(orderItem);
    } else {
      orderItem.quantity -= 1;
      this.changeBasketItemQuantity(orderItem);
    }

  }

  plusBasketItemQuantity(orderItem: OrderItem) {
    orderItem.quantity += 1;
    this.changeBasketItemQuantity(orderItem);
  }

  removeBasketItem(orderItem: OrderItem) {
    this.order.orderItems.splice(this.order.orderItems.indexOf(orderItem), 1);
    this.shop.order.next(this.order);
    this.shop.calculateOrder().subscribe((c) => {
      // calculate done
    });
  }

  manualBasketItemQuantity(orderItem: OrderItem) {
    // Debounced with this._timeout
    if (this._timeout) { // if there is already a timeout in process cancel it
      window.clearTimeout(this._timeout);
    }
    this._timeout = window.setTimeout(() => {
      this._timeout = null;
      this.changeBasketItemQuantity(orderItem);
    }, 250);
  }

  changeBasketItemQuantity(orderItem: OrderItem) {
    if (!orderItem.quantity || orderItem.quantity < 1) {
      setTimeout(() => {
        orderItem.quantity = 1;
      }, 1000);
      return;
    }
    this.shop.order.next(this.order);
    this.shop.calculateOrder().subscribe((c) => {
      // calculate done
    });
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  hasUnavailableOrderItems() {
    let has = false;
    if (this.order.orderItems) {
      this.order.orderItems.forEach((o) => {
        if (o.isAvailable === false) {
          has = true;
        }
      });
    }
    return has;
  }

}
