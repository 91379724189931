<div class="content-padding">
  <h2>{{ 'USER_AREA_H2' | translate }}</h2>
  <div class="items">
    <div class="item" *ngFor="let i of items">
      <app-fold-item title="{{ i.title | translate }}" [isOpen]="i.isOpen" size="big" [content]="i.content"
        (triggerItem)="triggerItem(i)" 
        *ngIf="((!accessData?.access_token && !i.auth) || (accessData?.access_token && i.auth)) && 
        ((area !== 'resetPassword' && i.content !== 'resetPassword') || (area === 'resetPassword' && i.content === 'resetPassword'))"></app-fold-item>
    </div>
  </div>
</div>