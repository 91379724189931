<div class="loading" *ngIf="loggingIn">
  <mat-spinner></mat-spinner>
</div>

<div class="success content-padding white-bg" *ngIf="forgotPasswordSuccess">
  {{ 'FORGOT_PASSWORD_SUCCESS' | translate}}
</div>

<div class="content-padding white-bg" *ngIf="!loggingIn && !forgotPasswordSuccess">
  <form class="login-form" (ngSubmit)="loginForm.form.valid && submit()" #loginForm="ngForm" id="loginForm" novalidate>
    <mat-form-field class="block" appearance="outline">
      <mat-label>{{ 'EMAIL' | translate }}</mat-label>
      <input matInput type="email" name="email" placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="user.email"
        #email="ngModel" required>
      <mat-error *ngIf="email.touched && !isValidEmail(user.email)">
        {{ 'ENTER_VALID_EMAIL' | translate}}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="block" appearance="outline" *ngIf="!showForgotPassword">
      <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
      <input matInput type="password" name="password" placeholder="{{ 'PASSWORD' | translate }}"
        [(ngModel)]="user.password" required>
    </mat-form-field>
    <div class="input-error" *ngIf="invalidCredentials">{{ 'INVALID_LOGIN_CREDENTIALS' | translate}}</div>  
    
    <div class="flex">
      <button type="submit" class="next" mat-flat-button color="primary" [disabled]="loggingIn">
        {{ (showForgotPassword ? 'FORGOT_PASSWORD' : 'LOGIN') | translate }}</button>
      <span class="fill-remaining-space"></span>
      <button type="button" class="previous" (click)="secondaryAction()" mat-button
        [disabled]="loggingIn">{{ (!showForgotPassword ? 'FORGOT_PASSWORD' : 'LOGIN') | translate }}</button>
    </div>
  </form>
</div>