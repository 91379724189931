<div class="loading" *ngIf="saving">
  <mat-spinner></mat-spinner>
</div>

<div class="success content-padding white-bg" *ngIf="success">
  {{ 'CHANGE_PASSWORD_SUCCESS' | translate}}
</div>

<div class="content-padding white-bg" *ngIf="!saving && !success">
  <form class="login-form" (ngSubmit)="loginForm.form.valid && submit()" #loginForm="ngForm" id="loginForm" novalidate>

    <mat-form-field class="block" appearance="outline" >
      <mat-label>{{ 'OLD_PASSWORD' | translate }}</mat-label>
      <input matInput type="password" name="oldPassword" placeholder="{{ 'OLD_PASSWORD' | translate }}"
        [(ngModel)]="password.oldPassword" required>
    </mat-form-field>

    <mat-form-field class="block" appearance="outline" >
      <mat-label>{{ 'NEW_PASSWORD' | translate }}</mat-label>
      <input matInput type="password" name="newPassword" placeholder="{{ 'NEW_PASSWORD' | translate }}"
        [(ngModel)]="password.newPassword" required>
    </mat-form-field>
    <mat-form-field class="block" appearance="outline" >
      <mat-label>{{ 'NEW_PASSWORD_REPEAT' | translate }}</mat-label>
      <input matInput type="password" name="newPasswordRepeat" placeholder="{{ 'NEW_PASSWORD_REPEAT' | translate }}"
        [(ngModel)]="password.newPasswordRepeat" required>
    </mat-form-field>

    <div class="errors">
      <div class="input-error" *ngIf="error">{{ 'CHANGE_PASSWORD_ERROR' | translate}}</div>
      <div class="input-error" *ngIf="passwordNoMatch">{{ 'PASSWORDS_NOT_MATCHING' | translate}}</div>
      <div class="input-error" *ngIf="invalidSign">{{ 'INVALID_SIGNS' | translate}} +</div>
    </div>

    <div class="flex">
      <button type="submit" class="next" mat-flat-button color="primary" [disabled]="saving">
        {{ ('CHANGE_PASSWORD') | translate }}</button>
    </div>
  </form>
</div>
