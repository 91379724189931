import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { ShopService } from '../services/shop.service';
import { Order } from '../models/order.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-basket-navigation',
  templateUrl: './basket-navigation.component.html',
  styleUrls: ['./basket-navigation.component.scss']
})
export class BasketNavigationComponent implements OnInit {

  order$: any;
  order: Order = null;
  basketItems = 0;

  constructor(
              private nav: NavigationService,
              private shop: ShopService,
              private router: Router,
              private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.order$ = this.shop.order.subscribe((o) => {
      this.order = o;
      this.calcBasketItems();
    });
  }

  openBasket() {
    // this.nav.col0.next('restaurants');
    // this.nav.col1.next('categories');
    // this.nav.col2.next('basket'); 
    const sq = JSON.parse(JSON.stringify(this.nav.shopQueryParams.value));

    sq.col2 = 'basket';
    this.nav.shopQueryParams.next(sq);
  }

  calcBasketItems() {
    if (!this.order.orderItems) { return; }
    let q = 0;
    this.order.orderItems.forEach((o) => {
      q += o.quantity;
    });
    let col2 = null;
    const sq = JSON.parse(JSON.stringify(this.nav.shopQueryParams.value) || null);
    if ((q > 0 && window.innerWidth > 1149) || this.nav.shopQueryParams.value.col2 === 'basket') {
      col2 = 'basket';
    }
    sq.col2 = col2;
    this.nav.col2.next(col2);
    this.nav.shopQueryParams.next(sq);
    // this.router.navigate(['.'], { relativeTo: this.route, queryParams: sq });
    this.basketItems = q;
  }

}
