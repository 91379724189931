import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  password = {
    newPassword: null,
    newPasswordRepeat: null,
    oldPassword: null
  };
  saving = false;
  success = false;
  error = false;
  passwordNoMatch = false;
  invalidSign = false;

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {
  }

  submit() {
    this.passwordNoMatch = false;
    this.invalidSign = false;
    if (this.password.newPassword.indexOf('+') !== -1 || this.password.newPasswordRepeat.indexOf('+') !== -1) {
      this.invalidSign = true;
      return;
    }
    if (this.password.newPassword !== this.password.newPasswordRepeat) {
      this.passwordNoMatch = true;
      return;
    }
    this.saving = true;
    this.success = false;
    this.error = false;

    this.auth.changePassword(this.password).subscribe(
      () => {
        this.saving = false;
        this.success = true;
      }, () => {
        this.saving = false;
        this.error = true;
      }
    );
  }

}
