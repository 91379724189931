
<div class="outer-container" *ngIf="basketItems > 0">
  <div class="container">
    <span class="fill-remaining-space"></span>
    <div matRipple class="basket-trigger" (click)="openBasket()">
      <span class="title">Warenkorb</span>
      <div class="badge">{{basketItems}}</div>
    </div>
  </div>
</div>
