import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { AccessData } from '../models/access-data.model';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Profile } from '../models/profile.model';
import { CreditCard } from '../models/credit-card.model';
import { PaymentMethod } from '../models/payment-method.model';

@Injectable()
export class AuthService {
  accessData = new BehaviorSubject<AccessData>(new AccessData());
  clientName = 'noobaAngularShop';
  clientSecret = 'dfU@tRJ0gJTG2ul41xBObKjZSOGl9jSAXEevE2xd7fKnhcIOJDb9';
  profile = new BehaviorSubject<Profile>(null);
  costumerId = null;

  public localUserData: any;

  constructor(
    private apiService: ApiService,
    private router: Router
  ) {
    this.localUserData = JSON.parse(localStorage.getItem('accessData'));
    if (this.localUserData) {
      this.handleNextAccessData(this.localUserData);
    }
  }

  handleNextAccessData(accessData: AccessData) {
    localStorage.setItem('accessData', JSON.stringify(accessData));
    this.accessData.next(accessData);
  }

  login(credentials): Observable<AccessData> {
    const formData = new HttpParams()
      .set('username', credentials.username)
      .set('password', credentials.password)
      .set('grant_type', 'password');
    return this.apiService.post('/Token', formData, 'form', false)
      .pipe(
        map(
          data => {
            console.log(data);
            this.handleNextAccessData(data);
            this.getProfile().subscribe(() => {
              // done
            });
            return data;
          }
        )
      );
  }

  getProfile() {
    return this.apiService.get('/api/Customers', 'json', true)
      .pipe(
        map(
          (data: Profile) => {
            this.profile.next(data);
            this.costumerId = data.customerId;
            return data;
          }
        )
      );
  }

  getCreditCards() {
    return this.apiService.get('/api/Customers/InnoCardPayment/My', 'json', true)
      .pipe(
        map(
          (data: Array<CreditCard>) => {
            return data;
          }
        )
      );
  }

  /* getPaymentMethods() {
    return this.apiService.get('/api/MasterData/AllowedPaymentTypes', 'json', true)
      .pipe(
        map(
          (data: Array<PaymentMethod>) => {
            return data;
          }
        )
      );
  } */

  getPaymentMethods() {
    return this.apiService.get('/api/Orders/AvailablePaymentmethods', 'json', true)
      .pipe(
        map(
          (data) => {
            return data;
          }
        )
      );
  }

  



  

  saveCreditCard(card) {
    return this.apiService.get('api/Customers/' + this.costumerId + '/PaymentMethods/'+ card, 'json', false).pipe(
      map(
        (data) => {

          return data;
        }
      ), catchError(
        (error) => {
          return throwError(error);
        }
      )
    );
  }

  finishCreditCard(costumerId,paymentMethodId){
    return this.apiService.patch('/api/Customers/'+ costumerId +'/PaymentMethods/Add/'+ paymentMethodId,null,'json',false).pipe(
      map(
        (data)=>{
          return data;
        }
      ),catchError(
        (error)=>{
          return throwError(error);
        }
      )
    );
  }

  
  deleteCreditCard(cardId) {
    return this.apiService.delete('/api/Customers/' + this.costumerId + '/PaymentMethods/' + cardId, {}, 'json', true).pipe(
      map(
        (data) => {

          return data;
        }
      )
    );
  }

  changePassword(pw) {
    return this.apiService.post('/api/Account/ChangePassword', pw, 'json', false).pipe(
      map(
        (data) => {

          return data;
        }
      )
    );
  }

  resetPassword(pw) {
    return this.apiService.post('/api/Account/ResetPassword', pw, 'json', false).pipe(
      map(
        (data) => {

          return data;
        }
      )
    );
  }

  register(user) {
    return this.apiService.post('/api/Account/Register', user, 'json', false).pipe(
      map(
        (data: AccessData) => {
          this.handleNextAccessData(data);

          return data;
        }
      ),
      catchError(
        (error) => {
          return throwError(error);
        }
      )
    );
  }

  getRefreshToken() {
    // tslint:disable-next-line:max-line-length
    const refreshToken = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData') || null).refresh_token : 'No refreshToken';
    const user = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: this.clientName,
      client_secret: this.clientSecret
    };
    return this.apiService.post('/token', user, 'json', false).pipe(
      map(
        (data) => {
          this.handleNextAccessData(data);

          return data;
        }
      )
    );
  }

  forgotPasswordRequest(credentials) {
    return this.apiService.post('/api/Account/ForgotPassword', credentials, 'json', false).pipe(
      map(
        data => {
          return data;
        }
      )
    );
  }

  forgotPasswordReset(credentials) {
    return this.apiService.post('/api/Account/ResetPassword', credentials, 'form', false).pipe(
      map(
        data => {
          return data;
        }
      )
    );
  }


  logout() {
    this.accessData.next(new AccessData());
    localStorage.removeItem('accessData');
  }

  makeGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  saveProfile(profile) {
    return this.apiService.put('/api/Customers', profile, 'json', true).pipe(
      map(
        (data: Profile) => {

          return data;
        }
      ),
      catchError(
        (error) => {
          return error;
        }
      )
    );
  }

}
