import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ShopQueryParams } from '../models/shop-query-params.model';

@Injectable()
export class NavigationService {

  shopQueryParams = new BehaviorSubject<ShopQueryParams>(null);

  col0 = new BehaviorSubject(null);
  col1 = new BehaviorSubject(null);
  col2 = new BehaviorSubject(null);

  constructor() { }

  paramsFromObject(params: { [key: string]: string }, prefix?: string): string {
    let result = prefix ? prefix : '';
    for (const key in params) {
      if (!params.hasOwnProperty(key) || !params[key]) {
        continue;
      }

      result += `/${key}/${params[key]}`;
    }
    return result;
  }
}
