<div class="content-padding">
  <h1>Wir sind alle Waste Warriors – Für eine Welt ohne Foodwaste</h1>
  <p>Too Good To Go lanciert eine neue Foodwaste Initiative und wir sind dabei!</p>
  <br>
  <p><strong>Aber was sind die WAW Brands?</strong></p>
  <p><strong>WAW</strong> = <strong>WA</strong>STE <strong>W</strong>ARRIOR BRANDS</p><br>
  <p>Die WAW Brands stehen für <strong>eine Vereinigung von Unternehmen, die gegen Lebensmittelverschwendung
      kämpft.</strong> Mit Too Good To Go an ihrer Seite sensibilisieren die WAW Brands nicht nur ihre
    Mitarbeiter*innen, sondern auch die Kund*innen stärker für Lebensmittelverschwendung und ergreifen zusätzliche
    Massnahmen dagegen.</p><br>
  <p>Wusstest du, dass 1/3 aller produzierten Lebensmittel verschwendet wird? Wir vom Nooba wollen unseren Teil zum
    Klimaschutz beitragen und Lebensmittelverschwendung noch weiter reduzieren. Wir möchten eine Vorbildfunktion für
    andere Unternehmen einnehmen, sowie für unsere eigenen Mitarbeitenden und Kunden einen Rahmen schaffen, in welchem
    ein stetiger Austausch und Lernprozess stattfindet.</p><br>
  <p>Weitere Infos findest du unter <a target="_blank" href="https://www.wawbrands.ch">www.wawbrands.ch</a></p>
  <div style="width: 100%; text-align: center;">
    <img width="60%" src="../../../assets/img/waw.png">
  </div>
</div>
