import { UrlSegment } from '@angular/router';

export class RoutingService {
  static readonly shopPrefix = 'shop';

  static routeMatcher(url: UrlSegment[], prefix: string) {
    if (url.length < 1 || !url[0].path.includes(prefix)) {
      return null;
    }

    const posParams: { [name: string]: UrlSegment } = {};

    for (let i = 1; i < url.length; i += 2) {
      if (!url[i + 1]) {
        continue;
      }

      const key = url[i].path;
      const value = url[i + 1].path;

      posParams[key] = new UrlSegment(value, {});
    }

    return {
      consumed: url,
      posParams
    };
  }
}
