import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from '../services/shop.service';
import { Product } from '../models/category.model';

@Component({
  selector: 'app-nutrition',
  templateUrl: './nutrition.component.html',
  styleUrls: ['./nutrition.component.scss']
})
export class NutritionComponent implements OnInit {

  routeSub: any;
  productId: number;
  product: any;

  constructor(
    private route: ActivatedRoute,
    private shopService: ShopService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe((params) => {
      this.productId = params['orderId'];
      if (this.productId) {
        this.shopService.getNutritionValues(this.productId).subscribe(
          (data) => {
            this.product = data;
          }, (err) => {
            console.log(err);
          }
        );
      }
    });
  }

  addSpacesAfterComma(string: string) {
    let newString;
    if (string) {
      newString = string.replace(/&nbsp/g, "").trim();
      newString = newString.replace(/[,]+/g, ", ").trim();
    }
    return newString ? newString : string;
  }
}
