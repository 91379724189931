import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ShopService } from 'src/app/services/shop.service';
import { Router } from '@angular/router';
import { Page } from 'src/app/models/page.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  iframeLoaded = false;

  iframeSrc: any;
  restaurant$: any;
  restaurant: any;
  loading = true;
  isKreuzplatz = false;
  isEuropaallee = false;

  @ViewChild('iframe', { static: true }) iframe: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private shop: ShopService,
    private elementRef: ElementRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.restaurant$ = this.shop.restaurant.subscribe((res) => {
      if (res) {
        if (res.restaurantId) {
          const r = this.shop.restaurant.value.restaurantId;
          // EUROPAALLEE
          if (r === 3) {
            this.isEuropaallee = true;
            this.isKreuzplatz = false;
          }
          // KREUZPLATZ
          if (r === 1) {
            this.isKreuzplatz = true;
            this.isEuropaallee = false;
          }
        }
      }
    });
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    this.restaurant$ = this.shop.restaurant.subscribe((res) => {
      if (res) {
        if (this.isEuropaallee) {
          const s = document.createElement('script');
          const tag = document.getElementById('iamenu');
          tag.setAttribute('data-iamenukey', 'cg44i6PH');
          // s.type = 'text/javascript';
          s.src = 'https://menuservice.inaffect.one/assets/js/publicvanilla.js';
          this.elementRef.nativeElement.appendChild(s);
          this.iframeLoaded = true;
          this.loading = false;
        }
        if (this.isKreuzplatz) {
          const s = document.createElement('script');
          const tag = document.getElementById('iamenu');
          tag.setAttribute('data-iamenukey', 'q9nDRc27');
          // s.type = 'text/javascript';
          s.src = 'https://menuservice.inaffect.one/assets/js/publicvanilla.js';
          this.elementRef.nativeElement.appendChild(s);
          this.iframeLoaded = true;
          this.loading = false;
        }
      }
    });
  }

  ngOnDestroy() {
    this.restaurant$.unsubscribe();
  }


}
