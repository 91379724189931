import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Page } from '../models/page.model';
import { ShopService } from '../services/shop.service';
import { DomSanitizer, Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  pages$: any;
  page: Page = null;
  iframeLoaded = false;

  iframeSrc: any;

  @ViewChild('iframe', { static: true }) iframe: ElementRef;

  constructor(
    private shop: ShopService,
    private sanitizer: DomSanitizer,
    private title: Title,
    private meta: Meta,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.handleSEO();
    this.translate.onLangChange.subscribe(() => {
      this.handleSEO();
    });

    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://twospice8-portal.rexx-recruitment.com/de/');
    setTimeout(() => {
      this.iframe.nativeElement.addEventListener('load', () => {
        this.iframeLoaded = true;
      });
    }, 10);
  }

  handleSEO() {
    this.title.setTitle(
      this.translate.instant('TITLE_TAG_JOBS')
    );
    this.meta.updateTag({
      name: 'description',
      content: this.translate.instant(
        'META_DESCRIPTION_JOBS'
      )
    });
  }

}
