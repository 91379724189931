<form autocomplete="on">
<div class="content-padding">
  <h2>{{ 'ORDER' | translate }}</h2>
  <!-- 
    <div *ngIf="longitude && latitude">
      <agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom">
        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
      </agm-map>
    </div> -->
  <div class="delivery-address">
    <h4>Für Lieferungen bitte Adresse eingeben</h4>
    <div class="gmaps-address">
      <mat-form-field class="address" appearance="outline">
        <mat-label>{{ 'ADDRESS' | translate }}</mat-label>
        <input matInput matGoogleMapsAutocomplete country="ch" name="address" [(ngModel)]="address"
          (ngModelChange)="onAddressChange()" (onAutocompleteSelected)="onAutocompleteSelected($event)"
          (onLocationSelected)="onLocationSelected($event)" #addressEl appAutoFocus autocomplete="on">
      </mat-form-field>
      <mat-form-field class="streetNumber" appearance="outline" *ngIf="!hasStreetNumber && !streetNumberSubmitted">
        <mat-label>{{ 'STREETNUMBER' | translate }}</mat-label>
        <input matInput type="text" name="streetNumber" [(ngModel)]="streetNumber" #streetNumberEl
          (ngModelChange)="onStreetNumberChange()" (keyup.enter)="onSubmitStreetNumber()" required>
        <button matSuffix mat-icon-button (click)="onSubmitStreetNumber()">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="errors">
      <div class="input-error" *ngIf="!hasStreetNumber && !streetNumber">
        {{ 'MISSING_STREETNUMBER_GOOGLE_AUTOCOMPLETE' | translate}}</div>
      <div class="input-error" *ngIf="!hasStreetNumber && streetNumber && streetNumberSubmitted">
        {{ 'ERROR_SELECT_GOOGLE_PLACES_ITEM' | translate}}</div>
      <div class="input-error" *ngIf="outOfDeliveryArea">{{ 'OUT_OF_DELIVERY_AREA_ADDRESS' | translate}}</div>
    </div>

  </div>
</div>

</form>
