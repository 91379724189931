import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShopService } from 'src/app/services/shop.service';
import { Page } from 'src/app/models/page.model';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnDestroy {

  pages$: any;
  page: Page = null;
  restaurant$: any;
  restaurant: any;
  loading = true;

  constructor(
    private shop: ShopService
  ) { }

  ngOnInit() {
    this.restaurant$ = this.shop.restaurant.subscribe((res) => {
      if (res) {
        if (res.restaurantId) {
          const r = this.shop.restaurant.value.restaurantId;
          this.pages$ = this.shop.pages.subscribe(
            (pages) => {
              console.log(pages)
              pages.forEach(c => {
                // c.slug === 'galerie-europaallee'
                if (r === 3 && c.id === 28) {
                  this.page = c;
                }
                // c.slug === 'galerie-kreuzplatz'
                if (r === 1 && c.id === 10) {
                  this.page = c;
                }
                // c.slug === 'galerie-laax'
                if (r === 2 && c.id === 31) {
                  this.page = c;
                }
                // c.slug === 'galerie-kesselhaus'
                if (r === 4 && c.id === 541) {
                  this.page = c;
                }
                console.log(this.page);
                this.loading = false;
              });
            }
          );
        }
      }
    });
  }

  ngOnDestroy() {
    this.restaurant$.unsubscribe();
  }

}
