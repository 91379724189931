export class CreditCard {
  innoCardPaymentId?: string;
  saveCard?: any;
  cardNumber?: any;
  cardType?: any;
  expiryMonth?: any;
  expiryYear?: any;
  cardSecurityCode?: any;
  createDate?: Date;
  validUntil?: Date;
  isValid?: boolean;
  cardHolder?: any;
}
