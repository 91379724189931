<div class="content-padding">
  <div class="loading" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="errors" *ngIf="!openDateTimes && !loading">
    <div class="input-error"  >
      <span>{{ 'RESTAURANT_NO_OPEN_DATE_TIMES_ERROR' | translate }}</span>
    </div>
  </div>

  <div class="datepicker" *ngIf="!loading">
    <mat-form-field appearance="outline" *ngIf="openDateTimes?.length > 0">
      <mat-label>{{ (type === 'takeout-time' ? 'TAKEOUT_DATE' : 'DELIVERY_DATE') | translate}}</mat-label>
      <input [(ngModel)]="date" matInput (click)="picker.open()" (ngModelChange)="onSelectDate()"
        placeholder="{{ (type === 'takeout-time' ? 'TAKEOUT_DATE' : 'DELIVERY_DATE') | translate}}"
        [matDatepicker]="picker" [min]="getMinDate()" [max]="getMaxDate()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="timepicker" *ngIf="times?.length > 0 && !loading">
    <mat-form-field appearance="outline">
      <mat-label>{{ (type === 'takeout-time' ? 'TAKEOUT_TIME' : 'DELIVERY_TIME') | translate}}</mat-label>
      <mat-select [(ngModel)]="time" (ngModelChange)="onSelectTime()">
        <mat-option *ngFor="let t of times" [value]="t" class="time-option">
          <div>{{ formatViewTime(t) }} {{ 'O_CLOCK' | translate }}</div>
          <span class="fill-remaining-space"></span>
          <div class="delivery-cost" *ngIf="orderType === 'delivery'">&nbsp;&nbsp;{{t.deliveryCosts | currencyNumber:'CHF':2}} {{ 'DELIVERY_COST' | translate }}</div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="input-error" *ngIf="date && times?.length < 1">{{ 'ERROR_NO_TIMES_SELECTED_DATE' | translate}}</div>
</div>

