<div class="content-padding">
  <div class="loading" *ngIf="!iframeLoaded && !isLaax">
    <mat-spinner></mat-spinner>
  </div>
  <!--<div *ngIf="isKreuzplatz">
    <div [hidden]="!iframeLoaded">
      <iframe #iframe [src]="iframeSrc" frameborder="0"></iframe>
    </div>
  </div>-->
  <div>
    <iframe *ngIf="iframeLoaded"
      [src]="merchantUrl | safe"
      frameBorder="0" width="100%" style="position: absolute;
      border: none;
      top: 10px; right: 0;
      bottom: 0; left: 0;">
    </iframe>
  </div>
  <!-- <div *ngIf="!isLaax">
    <p>{{ 'RESERVATION_FEE' | translate }}</p>
  </div> -->
</div>