import { Component, OnInit } from '@angular/core';
import { ShopService } from 'src/app/services/shop.service';
import { Page } from 'src/app/models/page.model';

@Component({
  selector: 'app-app-marketing',
  templateUrl: './app-marketing.component.html',
  styleUrls: ['./app-marketing.component.scss']
})
export class AppMarketingComponent implements OnInit {

  pages$: any;
  page: Page = null;

  constructor(
    private shop: ShopService
  ) { }

  ngOnInit() {
    this.pages$ = this.shop.pages.subscribe(
      (pages) => {
        pages.forEach(c => {
          if (c.slug === 'laax-takeout') {
            this.page = c;
          }
        });
      }
    );
  }

}
