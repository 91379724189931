import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ShopService } from 'src/app/services/shop.service';
import { OpenDateTime } from 'src/app/models/open-date-time.model';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-time-content',
  templateUrl: './time-content.component.html',
  styleUrls: ['./time-content.component.scss']
})
export class TimeContentComponent implements OnInit, OnDestroy {

  selectedDateTime$: any;
  selectedDateTime = null;
  time: any;
  date: Date;
  openDateTimes$: any;
  openDateTimes: Array<OpenDateTime> = null;
  times: Date[] = null;
  date$: any;
  time$: any;
  orderType: any;
  loading = true;

  @Input() type: string;
  @Input() isOpen: boolean;

  constructor(
    private shop: ShopService
  ) {}

  ngOnInit() {
    this.shop.getOpenDateTimes().subscribe(
      (d) => {
        // done
        this.loading = false;
      }
    );
    this.orderType = this.shop.orderType.value;
    this.openDateTimes$ = this.shop.openDateTimes.subscribe(
      (o) => {
        this.openDateTimes = o;
      }
    );
    this.selectedDateTime$ = this.shop.selectedDateTime.subscribe(
      (s) => {
        this.selectedDateTime = s;
      }
    );
    this.date$ = this.shop.selectedDate.subscribe(
      (d) => {
        this.date = d;
        this.setAvailableTimes();
      }
    );
    this.time$ = this.shop.selectedTime.subscribe(
      (d) => {
        this.time = d;
      }
    );
  }



  ngOnDestroy() {
    this.selectedDateTime$.unsubscribe();
    this.openDateTimes$.unsubscribe();
    this.date$.unsubscribe();
    this.time$.unsubscribe();
  }

  onSelectDate() {
    const userTimezoneOffset = this.date.getTimezoneOffset() * 60000;
    const date = new Date(this.date.getTime() - userTimezoneOffset);
    this.shop.selectedDate.next(date);
    this.setAvailableTimes();
  }

  setAvailableTimes() {
    if (!this.date) { return; }
    // set available times
    this.times = null;
    this.openDateTimes.forEach(
      (o) => {
        if (o.date.substring(0, 10) === this.date.toISOString().substring(0, 10)) {
          this.times = o.times;
        }
      }
    );
  }

  onSelectTime() {
    let timeDate;
    const o = this.shop.orderType.value;
    if (o === 'takeout') {
      this.shop.selectedTime.next(this.time);
      // timeDate = (new Date(this.time));
      timeDate = moment(this.time).toDate();
    }
    if (o === 'delivery') {
      this.shop.selectedTime.next(this.time);
      // timeDate = (new Date(this.time.time));
      timeDate = moment(this.time.time).toDate();
      this.shop.restaurants.value.forEach((r) => {
        if (r.restaurantId === this.time.restaurantId) {
          this.shop.restaurant.next(r);
        }
      });
    }


    let selectedDateTime = new Date(this.date.setHours(timeDate.getHours()));
    selectedDateTime = new Date(selectedDateTime.setMinutes(timeDate.getMinutes()));
    this.shop.selectedDateTime.next(moment(selectedDateTime).format('YYYY-MM-DDTHH:mm:SS'));

    const order = this.shop.order.value;
    order.deliveryTime = this.shop.selectedDateTime.value;

    this.shop.order.next(order);
    this.shop.calculateOrder().subscribe(
      () => {}
    );

    this.closeFoldItem();
  }

  closeFoldItem() {
    this.isOpen = false;
  }

  getMinDate() {
    return new Date(this.openDateTimes[0].date);
  }

  getMaxDate() {
    return new Date(this.openDateTimes[this.openDateTimes.length - 1].date);
  }

  formatViewTime(time) {
    const o = this.shop.orderType.value;
    if (o === 'takeout') {
      return time.substring(11, 16); // remove date and seconds
    }
    if (o === 'delivery') {
      return time.time.substring(11, 16); // remove date and seconds
    }
  }

}
