import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class ApiService {
  public bearerToken: string;
  public currentUser: any;
  loading = false;

  constructor(
              private http: HttpClient,
              private translateService: TranslateService,
              private device: DeviceDetectorService
  ) { }

  setHeaders(headerType, authenticate) {
    const headersConf = {};
    const deviceInfo = this.device.getDeviceInfo();
    headersConf['Accept-Language'] = this.translateService.currentLang;
    headersConf['X-Version'] = environment.majorVersion + '.' + environment.minorVersion + '.' + environment.commitNumber;
    headersConf['X-Platform'] = deviceInfo.browser + ' ' + deviceInfo.browser_version;
    headersConf['X-Platform-Version'] = deviceInfo.os + ' ' + deviceInfo.os_version;
    if (headerType === 'json') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'json-x') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'form') {
      headersConf['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    } else if (headerType === 'multipart') {
      headersConf['Content-Type'] = 'multipart/form-data';
    }
    // Authorization
    if (authenticate) {
      if (localStorage.getItem('accessData')) {
        const accessData = JSON.parse(localStorage.getItem('accessData') || null );
        headersConf['Authorization'] = 'bearer ' + accessData.access_token;
      }
    }
    return new HttpHeaders(headersConf);
  }

  get(path: string, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(environment.api_url + path, { headers });
  }

  getVote(path: string, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(path, { headers });
  }

  patch(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.patch(environment.api_url + path, body, { headers });
  }

  post(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.post(environment.api_url + path, body, { headers });
  }

  postVote(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.post(path, body, { headers });
  }

  put(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.put(environment.api_url + path, body, { headers });
  }

  delete(path: string, body, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.delete(environment.api_url + path, { headers });
  }


}
