import { CreditCard } from './credit-card.model';
import { Address } from './address.model';

export class OrderItem {
    productId: string;
    quantity: number;
    isAvailable?: boolean;
}

export class Order {
    languageId?: any;
    clientTypeId: number;
    restaurantId: number;
    deliveryZoneId: number;
    customerId?: string;
    enableExpressCheckout: boolean;
    promoCodeList: any[];
    voucherList: any[];
    storeCardList: any[];
    orderItems: OrderItem[];
    bruttoTotal: any;
    personsAmount?: number;
    message?: string;
    paymentMethodId?: number;
    paymentInfo?: CreditCard;
    address?: Address;
    deliveryTime?: string;
    deliveryCosts?: number;
    saveCard?:boolean;
}
