<div class="loading" *ngIf="registering">
  <mat-spinner></mat-spinner>
</div>

<div class="content-padding white-bg" *ngIf="error">
  <div class="input-error" *ngIf="error.status === 409" >{{ 'REGISTRATION_ERROR_409' | translate }}</div>
  <div class="input-error" *ngIf="error.status !== 409">{{ 'REGISTER_ERROR' | translate }}</div>
</div>
<div class="content-padding white-bg" *ngIf="loginError">
  <div class="input-error">{{ 'LOGIN_ERROR' | translate }}</div>
</div>

<div class="content-padding white-bg" *ngIf="!registering">
  <app-address type="registration" [address]="address" (formSubmit)="register()"></app-address>
  <div class="input-error" *ngIf="invalidSign">{{ 'INVALID_SIGNS' | translate}} +</div>
  <button form="addressForm" class="next"
     mat-flat-button
    color="primary" [disabled]="registering">
    {{ 'REGISTER' | translate }}</button>
</div>
