<div class="loading" *ngIf="saving || loadingProfile">
  <mat-spinner></mat-spinner>
</div>

<div class="content-padding white-bg" *ngIf="error">
  <div class="input-error">{{ 'SAVE_PROFILE_ERROR' | translate }}</div>
</div>

<div class="content-padding white-bg" *ngIf="!saving && !loadingProfile">
  <app-address *ngIf="profileLoaded" type="profile" [address]="address" (formSubmit)="saveProfile()"></app-address>
  
  <app-credit-card [card]="card"
     type="add-card"></app-credit-card>
  
  <button form="addressForm" class="next" mat-flat-button color="primary" [disabled]="saving">
    {{ 'SAVE_PROFILE' | translate }}</button>
</div>