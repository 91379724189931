<div class="navbar">
  <div class="logo" (click)="goToShopStart()">  
      <img src="./assets/img/logo.svg">
  </div>
  <span class="fill-remaining-space"></span>
  <div>
      <button routerLink="/user" mat-icon-button color="primary">
        <!-- <mat-icon aria-label="My Nooba" svgIcon="user"></mat-icon> -->
        <svg xmlns="http://www.w3.org/2000/svg" width="29.063" height="29.063" viewBox="0 0 29.063 29.063">
          <g id="Gruppe_50" data-name="Gruppe 50" transform="translate(-183.536 -73.775)">
            <path id="Pfad_37" data-name="Pfad 37"
              d="M198.067,73.775c9.472,0,14.531,6.353,14.531,14.507,0,7.729-3.818,14.556-14.531,14.556s-14.531-6.827-14.531-14.556c0-8.153,5.06-14.507,14.531-14.507"
              transform="translate(0 0)" fill="#641b65" />
            <path id="Pfad_38" data-name="Pfad 38"
              d="M208.505,156.237q.074-2.143,3.327-3.511a15.841,15.841,0,0,1,6.134-1.368,15.548,15.548,0,0,1,6.134,1.4q3.251,1.405,3.327,3.476c0,2.549-5.47,5.1-9.461,5.1s-9.461-2.553-9.461-5.1"
              transform="translate(-19.9 -61.832)" fill="#fef8ee" />
            <path id="Pfad_39" data-name="Pfad 39"
              d="M236.412,90.2a4.455,4.455,0,0,1,4.759,4.752c0,2.531-1.252,4.769-4.759,4.769s-4.763-2.238-4.763-4.769a4.458,4.458,0,0,1,4.763-4.752"
              transform="translate(-38.345 -13.088)" fill="#fef8ee" />
          </g>
        </svg>
      </button>
  </div>
</div>
