import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../services/navigation.service';
import { ShopQueryParams } from '../models/shop-query-params.model';
import { UserQueryParams } from '../models/user-query-params.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public tosLink: string = '';
  public dataProtectionLink: string = '';

  constructor(
    private translate: TranslateService,
    private nav: NavigationService
  ) { }

  ngOnInit() {
    this.setLinks()
  }

  setLinks() {
    const lang = this.currentLanguage()
    if (lang === 'de') {
      this.tosLink = 'https://www.twospice.ch/agb/'
      this.dataProtectionLink = 'https://www.twospice.ch/datenschutz/#websitecookie'
    } else {
      this.tosLink = 'https://www.twospice.ch/tos/'
      this.dataProtectionLink = 'https://www.twospice.ch/data-protection/#websitecookie'
    }
  }

  setLanguage(lang) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    setTimeout(() => {
      this.setLinks()

    }, 500);
  }

  currentLanguage() {
    return this.translate.currentLang;
  }

  goToURL(url: string) {
    window.open(url);
  }

}
