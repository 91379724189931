import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from './services/auth.service';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ErrorService } from './services/error.service';

// import * as dialogs from 'ui/dialogs';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private authService: AuthService,
        private errorService: ErrorService
    ) { }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    // tslint:disable-next-line:max-line-length
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        console.log("intercept: " + req.url);
        // skip interception if .svg
        if (req.url.search('.svg') > -1) {
            return next.handle(req);
        }
        // reset errors
        this.errorService.setError(new Error());
        const accessData = localStorage.getItem('accessData') ? JSON.parse(localStorage.getItem('accessData') || null) : undefined;
        const token = accessData ? accessData.access_token : 'Kein accessData / Access Token im Storage';

        // tslint:disable-next-line:no-angle-bracket-type-assertion
        return <any> next.handle(this.addToken(req, token))
            .pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        // tslint:disable-next-line:no-angle-bracket-type-assertion
                        switch ((<HttpErrorResponse> error).status) {
                            case 400:
                                const splitted = req.url.split('/');
                                const urlPath = splitted[splitted.length - 1];
                                if (urlPath === 'Token') {
                                    this.errorService.setError({ message: 'Ungültige Zugangsdaten' });
                                    console.log(error);
                                    return throwError(error);
                                } else {
                                    return this.handle400Error();
                                }
                            case 401:
                                if (!accessData) {
                                    this.logoutUser();
                                    return;
                                } else {
                                    return this.handle401Error(req, next);
                                }
                            case 402:
                                return throwError(error);
                            case 403:
                                return this.handle403Error();
                            case 406:
                                return throwError(error);
                            case 409:
                                return throwError(error);
                            case 500:
                                return throwError(error);
                        }
                    } else {
                        return throwError(error);
                    }
                })
            );
    }

    // Error 400: Refresh Token was invalid, logout and redirect to Login
    handle400Error(): any {
        this.logoutUser();
    }

    // Error 401: Access Token was invalid, try to get new Access Token by Refresh Token
    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const rDate = new Date(JSON.parse(JSON.stringify(localStorage.getItem('accessData')) || null).refreshTokenExpireIn);
            const nDate = new Date();

            if (nDate < rDate) {
                return this.authService.getRefreshToken()
                    .pipe(
                        switchMap((newToken) => {
                            if (newToken.accessToken) {
                                this.tokenSubject.next(newToken.accessToken);

                                return next.handle(this.addToken(req, newToken.accessToken));
                            }

                            // If we don't get a new token, we are in trouble so logout.
                            return this.logoutUser();
                        }),
                        catchError((error) => {
                            // If there is an exception calling 'refreshToken', bad news so logout.
                            return this.logoutUser();
                        }),
                        finalize(() => {
                            this.isRefreshingToken = false;
                        })
                    );
            } else {
                // refresh token is expired, logout immediately
                return this.logoutUser();
            }


        } else {
            return this.tokenSubject
                .pipe(
                    filter((token) => token != null),
                    take(1),
                    switchMap((token) => {
                        return next.handle(this.addToken(req, token));
                    })
                );
        }
    }

    // Error 403: Access Token was valid, but user has no access to requested resource
    handle403Error(): any {
        alert('Error 403: Access Denied. You do not have permission to access the requested resource.');
    }

    logoutUser() {
        this.authService.logout();

        return throwError([]);
    }

}
