import { Component, OnInit } from '@angular/core';
import { ShopService } from '../services/shop.service';
import { Order } from '../models/order.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(
    private shop: ShopService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  goToShopStart() {
    this.shop.order.next(new Order());
    this.router.navigateByUrl('/shop');

  }

}
