export class Address {
  company: string;
  sex: number;
  title?: any;
  firstName: string;
  lastName: string;
  street: string;
  zip: string;
  city: string;
  country?: any;
  email: string;
  phone?: any;
  mobilePhone: string;
  newsletter: boolean;
  amountPersons?: any;
  message?: any;
  password?: any;
  repeatPassword?: any;
  languageCode?: any;
  birthDate?: any;
  infoMailAllowed?: any;
  agreement?: any;
}
