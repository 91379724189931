import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  route$: any;
  userId: string;
  confirmCode: string;
  password = {
    newPassword: null,
    newPasswordRepeat: null,
    oldPassword: null
  };
  saving = false;
  success = false;
  error = false;
  passwordNoMatch = false;
  invalidSign = false;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route$ = this.route.queryParams.subscribe(
      (params) => {
        if (params.userId) {
          this.userId = params.userId;
        }
        if (params.confirmCode) {
          this.confirmCode = params.confirmCode;
        }
      }
    );
  }

  ngOnDestroy() {
    this.route$.unsubscribe();
  }

  submit() {
    this.passwordNoMatch = false;
    this.invalidSign = false;
    if (this.password.newPassword.indexOf('+') !== -1 || this.password.newPasswordRepeat.indexOf('+') !== -1) {
      this.invalidSign = true;
      return;
    }
    if (this.password.newPassword !== this.password.newPasswordRepeat) {
      this.passwordNoMatch = true;
      return;
    }
    if (!this.userId || !this.confirmCode) {
      return;
    }
    this.saving = true;
    this.success = false;
    this.error = false;
    const body = {
      userId: this.userId,
      confirmCode: this.confirmCode,
      newPassword: this.password.newPassword
    };
    this.auth.resetPassword(body).subscribe(
      () => {
        this.saving = false;
        this.success = true;
      }, () => {
        this.saving = false;
        this.error = true;
      }
    );
  }

  goToLogin() {
    this.router.navigateByUrl('user/login');
  }

}
