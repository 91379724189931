export class AccessData {
    access_token: string;
    token_type: string;
    expires_in: number;
    userName: string;
    userId: string;
    eMail?: any;
    isSysAdmin: boolean;
    isAdmin: boolean;
    isBranchAdmin: boolean;
    isB2BAdmin: boolean;
    isUser: boolean;
    roles: string;
}
