<div class="content-padding" [style.margin-bottom]="getContentMargin()">
  <h2>{{ 'BASKET' | translate }}</h2>
  <app-basket-items [step]="step"></app-basket-items>
  <div class="step1" *ngIf="step === 1">
    <app-fold-item [title]="(orderType === 'delivery' ? 'DELIVERY_TIME' : 'TAKEOUT_TIME') | translate"
      [isOpen]="timeOpen" size="normal" content="{{ orderType === 'delivery' ? 'delivery-time' : 'takeout-time'}}"
      (triggerItem)="toggleTime()"
      [rightDisplayValue]="(orderType === 'delivery' ? selectedDateTime?.time : selectedDateTime) | localizedDate">

    </app-fold-item>
    <div class="errors">
      <div class="input-error" *ngIf="timeMissingError">
        <span *ngIf="orderType === 'delivery'">{{ 'MISSING_DELIVERY_TIME_ERROR' | translate }}</span>
        <span *ngIf="orderType === 'takeout'">{{ 'MISSING_TAKEOUT_TIME_ERROR' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="step2" *ngIf="step === 2">
    <h4 *ngIf="orderType === 'delivery'">{{ 'ORDER' | translate }}</h4>
    <h4 *ngIf="orderType === 'takeout'">{{ 'TAKEOUT' | translate }}</h4>
    <div class="guest-user-selection">
      <mat-radio-group [(ngModel)]="userType" (ngModelChange)="onUserTypeChange()">
        <mat-radio-button color="primary" class="block" style="margin:5px 0px;" [value]="'guest'">
          {{'I_AM_GUEST' | translate}}
        </mat-radio-button>
        <mat-radio-button color="primary" class="block" style="margin:5px 0px;" [value]="'registered'">
          {{'I_AM_REGISTERED' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="login" *ngIf="userType === 'registered' && !accessData?.access_token">
      <form class="login-form" (ngSubmit)="loginForm.form.valid && login()" #loginForm="ngForm" id="loginForm"
        novalidate>
        <mat-form-field class="block" appearance="outline">
          <mat-label>{{ 'EMAIL' | translate }}</mat-label>
          <input matInput type="email" name="email" placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="user.email"
            #email="ngModel" required>
          <mat-error *ngIf="email.touched && !isValidEmail(user.email)">
            {{ 'ENTER_VALID_EMAIL' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="block" appearance="outline">
          <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
          <input matInput type="password" name="password" placeholder="{{ 'PASSWORD' | translate }}"
            [(ngModel)]="user.password" required>
        </mat-form-field>
        <div class="input-error" *ngIf="invalidCredentials">{{ 'INVALID_LOGIN_CREDENTIALS' | translate}}</div>
      </form>
    </div>
    <app-address [address]="address" [disableStreet]="orderType === 'delivery'"
      [showAddressFields]="orderType != 'takeout'"
      *ngIf="userType === 'guest' || (userType === 'registered' && accessData?.access_token)" (formSubmit)="nextStep()"
      type="checkout"></app-address>
  </div>
  <div class="step3" *ngIf="step === 3">
    <h4>{{ 'PAYMENT' | translate }}</h4>
    <!-- <div class="payment-type-selection">
      <mat-radio-group [(ngModel)]="paymentType" (ngModelChange)="onPaymentTypeChange()" [disabled]="creatingOrder">
        <mat-radio-button color="primary" class="block" [value]="'card'">
          {{'CREDIT_CARD' | translate}}
        </mat-radio-button>
        <mat-radio-button color="primary" class="block" [value]="'cash'" *ngIf="orderType === 'takeout'">
          {{'CASH' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div> -->
    <div class="payment-type-selection">
      <mat-radio-group [(ngModel)]="paymentType" (ngModelChange)="onPaymentTypeChange()" [disabled]="creatingOrder">
        <mat-radio-button color="primary" *ngIf="userType === 'registered' && savedCards.length >0" class="block"
          style="margin:5px 0px;" [value]="2">
          {{'SAVED_CARDS' | translate}}
        </mat-radio-button>
        <mat-form-field style="margin:0px 20px;" *ngIf="userType === 'registered' && savedCards.length >0 && paymentType===2">
          <mat-label>{{'CHOOSE_CARD' | translate}}</mat-label>
          <mat-select [(ngModel)]="selectedCard">
            <mat-option *ngFor="let s of savedCards" [value]="s.paymentMethodId">
              {{s.cardType}}  <span *ngIf="s.cardNumber">-  {{s.cardNumber}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container *ngFor="let p of paymentMethods; let i = index">
          <mat-radio-button color="primary" class="block" style="margin:5px 0px;" [value]="i+3">
            {{p.Name}}<span style="font-size: 10px;" *ngIf="p.Description"> ({{p.Description}})</span>
          </mat-radio-button>
          <mat-checkbox color="primary" [(ngModel)]="saveCard" class="block" style="margin:5px 10px;"
            *ngIf="userType === 'registered' && i===0 && paymentType===3">{{'SAVE_CARD' | translate}}
          </mat-checkbox>
        </ng-container>

        <mat-radio-button color="primary" class="block" style="margin:5px 0px;" [value]="1"
          *ngIf="orderType === 'takeout'">
          {{'CASH' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="loading" *ngIf="creatingOrder">
      <mat-spinner></mat-spinner>
    </div>
    <div class="errors">
      <div class="input-error" *ngIf="paymentTypeMissingError">
        <span>{{ 'PAYMENT_TYPE_MISSING_ERROR' | translate }}</span>
      </div>
      <div class="input-error" *ngIf="createOrderError">
        <span>{{ 'CREATE_ORDER_ERROR' | translate }}</span>
      </div>
    </div>
    <!--<app-credit-card [card]="card" *ngIf="paymentType !== 'cash' && paymentType && !creatingOrder && !calculating" (formSubmit)="nextStep()" type="checkout"></app-credit-card> -->
  </div>

  <div class="actions">
    <button class="next" type="button" *ngIf="step !== 2 && step !== 3" (click)="nextStep()" mat-flat-button
      color="primary" [disabled]="calculating || order.orderItems.length < 1">
      <span>{{ 'NEXT' | translate }}</span>
    </button>
    <button type="submit" form="loginForm" class="next"
      *ngIf="(step === 2 && userType === 'registered' && !accessData?.access_token)" mat-flat-button color="primary"
      [disabled]="loggingIn || calculating">
      {{ 'LOGIN' | translate }}</button>
    <button form="addressForm" class="next"
      *ngIf="((step === 2 && accessData?.access_token) || (step === 2 && userType === 'guest'))" mat-flat-button
      color="primary" [disabled]="calculating">
      {{ 'NEXT' | translate }}</button>
    <button type="submit" class="next" (click)="nextStep()" *ngIf="step === 3 && paymentType !== 1" mat-flat-button
      color="primary" [disabled]="calculating || creatingOrder || !paymentType || (paymentType===2 && !selectedCard)">
      {{ 'PAY' | translate }}</button>
    <button type="submit" class="next" (click)="nextStep()" *ngIf="step === 3 && paymentType === 1" mat-flat-button
      color="primary" [disabled]="calculating || creatingOrder">
      {{ 'SEND_ORDER' | translate }}</button>
    <span class="fill-remaining-space"></span>
    <button class="previous" (click)="previousStep()" *ngIf="step > 1 && step < 4" mat-button
      [disabled]="calculating || creatingOrder">{{ 'BACK' | translate }}</button>
  </div>

</div>