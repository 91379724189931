import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Error } from '../models/error.model';

@Injectable()
export class ErrorService {
    error = new BehaviorSubject<Error>(new Error());

    setError(error) {
        this.error.next(error);
    }
}
