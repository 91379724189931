import { Component, OnInit, OnDestroy } from '@angular/core';
import { Page } from 'src/app/models/page.model';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'app-wp-menu',
  templateUrl: './wp-menu.component.html',
  styleUrls: ['./wp-menu.component.scss']
})
export class WpMenuComponent implements OnInit, OnDestroy {

  pages$: any;
  page: Page = null;
  restaurant$: any;
  restaurantId: any;
  loading = true;

  constructor(
    private shop: ShopService
  ) { }

  ngOnInit() {
    this.restaurant$ = this.shop.restaurant.subscribe((res) => {
      if (res) {
        if (res.restaurantId) {
          const r = this.shop.restaurant.value.restaurantId;
          this.restaurantId = r;
          this.pages$ = this.shop.pages.subscribe(
            (pages) => {
              pages.forEach(c => {
                if (r === 3 && c.slug === 'menu-europaallee') {
                  this.page = c;
                }
                if (r === 1 && c.slug === 'menu-kreuzplatz') {
                  this.page = c;
                }
                if (r === 2 && c.slug === 'menu-laax') {
                  this.page = c;
                }
                if (r === 4 && c.slug === 'menu-kesselhaus') {
                  this.page = c;
                }
                if (r === 5 && c.slug === 'menu-andermatt') {
                  this.page = c;
                }
                this.loading = false;
              });
            }
          );
        }
      }
    });
  }

  ngOnDestroy() {
    this.restaurant$.unsubscribe();
  }
}
