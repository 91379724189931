import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Product } from 'src/app/models/product.model';
import { ShopService } from '../services/shop.service';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent implements OnInit {

  nutrition = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProductDialogComponent>,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  getProductLang(p: Product) {
    return p.productLanguages.find(o => o.languageKuerzel === this.translate.currentLang);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addToBasket() {
    this.dialogRef.close(this.data.product);
  }

  addSpacesAfterComma(string: string) {
    let newString;
    if (string) {
      newString = string.replace(/&nbsp/g, '').trim();
      newString = newString.replace(/[,]+/g, ', ').trim();
    }
    return newString ? newString : string;
  }
}
