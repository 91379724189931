import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ShopService } from 'src/app/services/shop.service';
import { AuthService } from 'src/app/services/auth.service';
import { AccessData } from 'src/app/models/access-data.model';
import { Profile } from 'src/app/models/profile.model';
import { FormGroupDirective } from '@angular/forms';
import { Address } from 'src/app/models/address.model';
import { CreditCard } from 'src/app/models/credit-card.model';
import { Angulartics2 } from 'angulartics2';
import { NavigationService } from '../../services/navigation.service';
import { Order } from '../../models/order.model';
import { ActivatedRoute, Router } from '@angular/router';
import { orderQueryParams } from 'src/app/models/order-query-params.model';
import { RoutingService } from 'src/app/services/routing.service';
import { HttpClient, HttpParams} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit, OnDestroy {

  step = 1;
  timeOpen = false;
  timeMissingError = false;
  orderType$: any;
  orderType = null;
  selectedDateTime: any;
  selectedDateTime$: any;
  userType: string; // guest, registered
  paymentType: null; // cash, card
  paymentTypeMissingError = false;
  address: Address = new Address();
  card: CreditCard = new CreditCard();
  savedCards = [];
  selectedCard: null;
  user = {
    email: null,
    password: null
  };
  invalidCredentials = false;
  loggingIn = false;
  accessData: AccessData;
  profile: Profile;
  profile$: any;
  createOrderError: any;
  creatingOrder = false;
  calculating = false;
  order: Order = null;
  order$: any;
  paymentMethods = [];
  saveCard = false;
  orderQueryParams: orderQueryParams = {
    col1: null,
    state: null,
    col2: null
  };

  @ViewChild('loginForm') documentEditForm: FormGroupDirective;

  constructor(
    private shop: ShopService,
    private auth: AuthService,
    private angulartics2: Angulartics2,
    private nav: NavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.fillAddressFromGoogle();
    this.shop.selectedDate.next(null);
    this.shop.selectedDateTime.next(null);
    this.shop.selectedTime.next(null);
    this.checkLocalStorageForAccessData();
    this.order$ = this.shop.order.subscribe(
      (o) => { this.order = o; }
    );
    this.selectedDateTime$ = this.shop.selectedDateTime.subscribe(
      (s) => {
        if (s !== this.selectedDateTime) {
          this.timeOpen = false;
          this.selectedDateTime = s;
          this.timeMissingError = false;
        }
      }
    );
    this.orderType$ = this.shop.orderType.subscribe(
      (o) => {
        this.orderType = o;
      }
    );
    this.profile$ = this.auth.profile.subscribe(
      (p) => {
        this.profile = p;
        this.fillAddressFromProfile();
      }
    );
  }

  ngOnDestroy() {
    this.orderType$.unsubscribe();
    this.selectedDateTime$.unsubscribe();
    this.profile$.unsubscribe();
    this.order$.unsubscribe();
  }



  toggleTime() {
    this.timeOpen = !this.timeOpen;
  }

  nextStep() {
    const order = this.shop.order.value;
    this.timeMissingError = false;
    this.paymentTypeMissingError = false;
    if (this.step === 1 && !this.selectedDateTime) {
      this.timeOpen = true;
      this.timeMissingError = true;
      return;
    }
    if (this.step === 2) {
      order.address = this.address;
      order.message = this.address.message;
      console.log(this.address.amountPersons);
      console.log(order.personsAmount);
      order.personsAmount = this.address.amountPersons;
      console.log(this.address.amountPersons);
      console.log(order.personsAmount);
      this.auth.getPaymentMethods().subscribe((res) => {
        let lang = this.translate.currentLang;
        console.log(lang);
        if (lang == 'de'){
          this.paymentMethods = res.AvailablePaymentMethodsDE;
        } else if (lang == 'en'){
          this.paymentMethods = res.AvailablePaymentMethodsEN;
        }else if (lang == 'fr'){
          this.paymentMethods = res.AvailablePaymentMethodsFR;
        } else {
          this.paymentMethods = res.AvailablePaymentMethods;
        }
        this.savedCards = res.SavedCreditCards;
        console.log(this.savedCards)
      });
      console.log(this.order);
    }
    if (this.step === 3) {
      if (!this.paymentType) {
        this.paymentTypeMissingError = true;
        return;
      }
      this.shop.card.next(this.card);
    }
    this.shop.order.next(order);
    this.calculating = true;
    this.shop.calculateOrder().subscribe((calc) => {
      this.calculating = false;
      if (this.step === 3) {
        this.order.saveCard = this.saveCard;
        this.createOrderError = null;
        this.creatingOrder = true;
        this.shop.sendOrder().subscribe((res) => {
          console.log(res);
          if (res.success && !res.redirectRequired) {
            this.creatingOrder = false;
            this.step += 1;
            this.shop.card.next(null);
            this.angulartics2.eventTrack.next({ action: 'orderSuccess' });
            this.shop.order.next(new Order());
            const sq = JSON.parse(JSON.stringify(this.orderQueryParams));
            sq.col1 = 'order-completed';
            sq.state = 'success?r='+res.orderId;
            sq.col2 = null;
            this.nav.col2.next(null);
            //this.router.navigate(['/shop/col1/order-completed',state])
            this.router.navigateByUrl(this.nav.paramsFromObject(sq, RoutingService.shopPrefix));
          } else if (res.success && res.redirectRequired) {
            this.creatingOrder = false;
            this.step += 1;
            this.shop.card.next(null);
            this.angulartics2.eventTrack.next({ action: 'orderSuccess' });
            window.location.href = res.paymentIntentValue;
          } else if (!res.success) {
            this.creatingOrder = false;
            this.createOrderError = true;
            this.angulartics2.eventTrack.next({ action: 'orderError' });

          }

        }, (err) => {
          console.log(err);
          this.creatingOrder = false;
          this.createOrderError = err;
          this.angulartics2.eventTrack.next({ action: 'orderError' });
        });
      } else {
        this.step += 1;
      }
    }, (err) => {
      this.calculating = false;
    });
  }

  previousStep() {
    if (this.step === 1) { return; }
    this.step -= 1;
  }

  isValidEmail(email) {
    let isValid = false;
    const mailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mailRegex.test(email.toString())) {
      isValid = true;
    }
    return isValid;
  }

  login() {
    this.invalidCredentials = false;
    this.loggingIn = true;
    this.auth.login({ username: this.user.email, password: this.user.password }).subscribe(
      (resp) => {
        this.loggingIn = false;
        this.accessData = resp;
      }, (err) => {
        this.invalidCredentials = true;
        this.loggingIn = false;
      }
    );
  }

  checkLocalStorageForAccessData() {
    if (localStorage.getItem('accessData')) {
      const accessData: AccessData = JSON.parse(localStorage.getItem('accessData') || null);
      this.auth.accessData.next(accessData);
      this.accessData = accessData;
      this.userType = 'registered';
      if (this.profile) {
        this.fillAddressFromProfile();
      } else {
        this.auth.getProfile().subscribe(() => { },
          (err) => { this.accessData = null; });
      }
    }
  }

  fillAddressFromProfile() {
    if (!this.profile) { return; }
    this.address = {
      company: this.profile.company,
      sex: this.profile.sex, // 0 female, 1 male
      title: null,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      street: this.profile.street,
      zip: this.profile.zip,
      city: this.profile.city,
      country: null,
      email: this.profile.email,
      phone: this.profile.phone,
      mobilePhone: null,
      newsletter: this.profile.infoMailAllowed,
      amountPersons: null
    };
    this.fillAddressFromGoogle();
    const order = this.shop.order.value;
    order.customerId = this.profile.customerId;
    this.shop.order.next(order);
    this.shop.calculateOrder().subscribe((r) => { });
  }

  fillAddressFromGoogle() {
    const f = this.shop.formattedGoogleAddress.value;
    if (!f || this.shop.orderType.value !== 'delivery') { return; }
    this.address.street = f.street_name + ' ' + f.street_number;
    this.address.zip = f.zip;
    this.address.city = f.city;
  }

  onUserTypeChange() {
    if (this.userType === 'guest') {
      for (const property in this.address) {
        if (this.address.hasOwnProperty(property)) {
          this.address[property] = null;
        }
      }
      this.address.amountPersons = null;
    }
    if (this.userType === 'registered') {
      this.checkLocalStorageForAccessData();
    }
    this.fillAddressFromGoogle();
  }

  onPaymentTypeChange() {
    console.log(this.selectedCard);
    if (this.paymentType) {
      const order = this.shop.order.value;
      order.paymentMethodId = this.paymentType === 1 ? 1 : 2;
      this.shop.order.next(order);
      this.paymentTypeMissingError = false;
    }
    if (this.paymentType !== 2){
      this.selectedCard = null;
    }
    console.log(this.paymentType);
    console.log(this.saveCard);
    this.saveCard = false;
  }

  getContentMargin() {
    return window.innerWidth > 1149 ? '30px' : ' 90px';
  }

}
