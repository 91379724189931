<div *ngIf="state==='success'" class="content-padding">
    <h2>{{ 'ORDER_SUCCESS' | translate }}</h2>
    <p>{{ 'ORDER_SUCCESS_PARAGRAPH' | translate }}</p>
</div> 
<div *ngIf="state==='abort' " class="content-padding">
    <h2>{{ 'ORDER_ABORT' | translate }}</h2>
    <p>{{ 'ORDER_ABORT_PARAGRAPH' | translate }}</p>
</div> 
<div *ngIf="state==='fail' " class="content-padding">
    <h2>{{ 'ORDER_FAIL' | translate }}</h2>
    <p>{{ 'ORDER_FAIL_PARAGRAPH' | translate }}</p>
</div> 