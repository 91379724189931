<div class="content-padding">
  <div class="loading" *ngIf="!iframeLoaded">
    <mat-spinner></mat-spinner>
  </div>
  <!--<div [hidden]="!iframeLoaded">
    <iframe #iframe [src]="iframeSrc" frameborder="0" id="iframe"></iframe>
  </div>-->
  <div id="iamenu"></div>
  
</div>
