import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Address } from 'src/app/models/address.model';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() address: Address;
  @Input() type: any;
  @Input() disableStreet: boolean;
  @Input() showAddressFields: boolean;
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();

  mailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  phoneRegex = /^\+[1-9]\d{10,14}$/;

  cutlery = [
    {
      display: ' ',
      value: null
    },
    {
      display: this.translate.instant('NO_CUTLERY'),
      value: 0
    },
    {
      display: '1',
      value: 1
    },
    {
      display: '2',
      value: 2
    },
    {
      display: '3',
      value: 3
    },
    {
      display: '4',
      value: 4
    },
    {
      display: '5',
      value: 5
    },
    {
      display: '6',
      value: 6
    },
    {
      display: '7',
      value: 7
    },
    {
      display: '8',
      value: 8
    },
    {
      display: '9',
      value: 9
    },
    {
      display: '10',
      value: 10
    }
  ]

  showPassword = false;


  constructor(
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {

  }

  onSubmit(form) {
    this.formSubmit.emit();
  }

  getMinDate() {
    return new Date('01.01.1900');
  }

  getMaxDate() {
    return new Date();
  }

  isValidEmail(email) {
    let isValid = false;
    if (email) {
      const mailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (mailRegex.test(email.toString())) {
        isValid = true;
      }
    }

    return isValid;
  }

  onShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onSelectDate() {
    // nil
  }


}
