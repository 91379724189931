import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShopService } from 'src/app/services/shop.service';
import { Category } from 'src/app/models/category.model';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {

  categories$: any;
  orderType$: any;
  restaurant$: any;
  categories: Array<Category> = [];
  category$: any;
  category: Category;
  loading = false;
  restaurant: any;
  orderType: any;

  signs = [];

  constructor(
    private shopService: ShopService,
    private translate: TranslateService,
    private nav: NavigationService
  ) { }

  ngOnInit() {
    this.signs = this.shopService.signs;
    this.getCategories();
    this.categories$ = this.shopService.categories.subscribe(
      (r) => {
        this.categories = r;
        if (this.categories) {
          if (this.nav.shopQueryParams.value) {
            this.categories.forEach(
              (c) => {
                if (c.categoryId === this.nav.shopQueryParams.value.categoryId) {
                  this.shopService.category.next(c);
                }
              }
            );
          }
        }
      }
    );
    this.category$ = this.shopService.category.subscribe(
      (r) => {
        this.category = r;
      }
    );
    this.orderType$ = this.shopService.orderType.subscribe(
      (r) => {
        if (r !== this.orderType) {
          this.orderType = r;
          this.getCategories();
        }
      }
    );
    this.restaurant$ = this.shopService.restaurant.subscribe(
      (r) => {
        if (r !== this.restaurant) {
          this.restaurant = r;
          this.getCategories();
        }
      }
    );
  }

  ngOnDestroy() {
    this.categories$.unsubscribe();
    this.category$.unsubscribe();
    // this.orderType$.unsubscribe();
    // this.restaurant$.unsubscribe();
  }

  getCategories() {
    if (!this.shopService.restaurant.value) {
      return;
    }
    this.loading = true;
    this.shopService.categories.next(null);
    this.shopService.getCategories().subscribe(arg => {
      this.loading = false;
    }, (err) => {
        const sq = JSON.parse(JSON.stringify(this.nav.shopQueryParams.value) || null);
        sq.orderType = 'delivery';
        sq.col1 = 'address';
        this.nav.shopQueryParams.next(sq);
    });
  }

  getCategoryName(category: Category) {
    return category.categoryLanguages.find(o => o.languageKuerzel === this.translate.currentLang).name;
  }

  selectCategory(category: Category) {
    this.shopService.products.next(null);
    this.shopService.category.next(category === this.category ? null : category);
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

}
