import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import * as creditCardType from 'credit-card-type';
import { CreditCard } from '../models/credit-card.model';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {

  cards: Array<CreditCard> = [];
  isLoggedIn = false;
  showCardForm = false;
  saving = false;
  error = false;
  paymentMethods = [];
  paymentType=null;

  @Input() card: CreditCard;
  @Input() type: string; // checkout, add-card
  @Output() formSubmit: EventEmitter<any> = new EventEmitter();

  @ViewChild('numberInputEl') numberInputEl: ElementRef;
  @ViewChild('cvvInputEl') cvvInputEl: ElementRef;
  @ViewChild('yearInputEl') yearInputEl: ElementRef;
  @ViewChild('monthInputEl') monthInputEl: ElementRef;
  @ViewChild('holderInputEl') holderInputEl: ElementRef;

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {
    const a = this.auth.accessData.value;
    if (a.access_token) {
      this.isLoggedIn = true;
      this.getCreditCards();
    }
  }

  getCreditCards() {
    //this.auth.getCreditCards().subscribe((c: Array<CreditCard>) => { this.cards = c; });
    this.auth.getPaymentMethods().subscribe((res) => {
      this.paymentMethods = res.AvailablePaymentMethods;
      this.cards = res.SavedCreditCards;
      console.log(this.cards)
    });
  }
  

  onSubmit(form) {
    console.log('here');
    this.error = false;
    if(this.paymentType==='Kreditkarte' || this.paymentType==='Creditcard' || this.paymentType==='Carte de crédit'){
      this.paymentType='CARD';
    }
    if (this.type === 'add-card') {
      this.saving = true;
      this.auth.saveCreditCard(this.paymentType).subscribe((success) => {
        // credit card added
        
        if (success.success && success.redirectRequired && !success.redirectRequired) {
          window.location.href = success.paymentIntentValue;
        }
        this.saving = false;
        this.getCreditCards();
        this.card = new CreditCard();
        this.showCardForm = false;
      }, (err) => {
        console.log(err);
        this.saving = false;
        this.error = true;
      });
    } else {
      this.formSubmit.emit();
    }
  }

  onPaymentTypeChange(){
    
    console.log(this.paymentType)
  }

  onChangeCardNumber() {
    const number = this.card.cardNumber;
    const start = number.substring(0, 2);
    if ((start === '34' || start === '37') && number.length === 17) {
      // amex
      this.getCCType(number);
      this.monthInputEl.nativeElement.focus();
    } else if (number.length === 19) {
      this.getCCType(number);
      this.monthInputEl.nativeElement.focus();
    } else {
      this.formatCCNumber(number);
    }
  }

  onChangeExpiryMonth() {
    if (this.card.expiryMonth.length === 2) {
      this.yearInputEl.nativeElement.focus();
    }
  }

  onChangeExpiryYear() {
    if (this.card.expiryYear.length === 2) {
      this.cvvInputEl.nativeElement.focus();
    }
  }

  onChangeCardSecurityCode() {
    // nothing
  }

  getCCType(number) {
    const type = creditCardType(number.replace(/\s/g, ''));
    this.card.cardType = type[0].niceType;
  }

  formatCCNumber(number) {
    const amexRegex = /^3[47][0-9]{5,}$/;
    const start = number.substring(0, 2);
    if (start === '34' || start === '37') {
      // potential amex card
      const formatted = number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/, '$1 ').replace(/(.{11})/, '$1 ').replace(/(.{17})/, '$1 ').trim();
      if (formatted !== number) {
        this.card.cardNumber = formatted;
      }
    } else if (number && number.length < 20) {
      const formatted = number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      if (formatted !== number) {
        this.card.cardNumber = formatted;
      }
    }
  }

  toggleCardForm() {
    this.showCardForm = !this.showCardForm;
  }

  deleteCard(card) {
    this.auth.deleteCreditCard(card.paymentMethodId).subscribe(
      (success) => {
        this.cards.splice(this.cards.indexOf(card), 1);
      }
    );
  }
}
