import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShopComponent } from './shop/shop.component';
import { UserComponent } from './user/user.component';
import { JobsComponent } from './jobs/jobs.component';
import { ImprintComponent } from './imprint/imprint.component';
import { RoutingService } from './services/routing.service';
import { NutritionComponent } from './nutrition/nutrition.component';
import { InitiativesComponent } from './shop/initiatives/initiatives.component';
import { AgbComponent } from './agb/agb.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: '', redirectTo: RoutingService.shopPrefix, pathMatch: 'full' },
  {
    matcher: (url) => RoutingService.routeMatcher(url, RoutingService.shopPrefix),
    component: ShopComponent,
  },
  {
    path: 'user',
    component: UserComponent
  },
  {
    path: 'user/:area',
    component: UserComponent
  },
  {
    path: 'card/:costumerId/:paymentMethodId/:state',
    component: UserComponent
  },
  {
    path: 'jobs',
    component: JobsComponent
  },
  {
    path: 'imprint',
    component: ImprintComponent
  },
  {
    path: 'impressum',
    component: ImprintComponent
  },
  {
    path: 'initiatives',
    component: InitiativesComponent
  },
  {
    path: 'nutrition/:orderId',
    component: NutritionComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
